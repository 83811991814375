import React, { useEffect, useState } from "react";
import {  Link} from "react-router-dom";
import axios from 'axios'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Header.css'

const Footer = () => {
  const [user, setUser] = useState([]);
  const [pro, setPro] = useState([]);

  useEffect(() => {
    // Fetch all "about us" entries when the component mounts
    const fetchUser = async () => {
      try {
        const response = await axios.get('/api/v1/user/countalluser');
        setUser(response.data.data);
      } catch (error) {
        console.error(error);
        // Handle error, show error message, etc.
      }
    };
    fetchUser();
  }, []);

useEffect(() => {
  // Fetch all "about us" entries when the component mounts
  const fetchPro = async () => {
    try {
      const response = await axios.get('/api/v1/user/countallpro');
      setPro(response.data.data);
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };
  fetchPro();
}, []);

  return (
    <Row className="compview" style={{backgroundColor:'#003C69',color:'white',margin:0, padding:0}}>
      <Col className='col-lg-2 col-xs-4' style={{margin:0,padding:10,fontSize:13}}>
      Important Links<br/>
        <Link to="/aboutus" style={{color:'white',textDecoration:'none'}}>About Us</Link><br/>
        <Link to="/blog" style={{color:'white',textDecoration:'none'}}>Blog</Link><br/>
        <Link to="/news" style={{color:'white',textDecoration:'none'}}>News Room</Link><br/>
        <Link to="/howitworks" style={{color:'white',textDecoration:'none'}}>How it Works</Link><br/><br/>
      </Col>
      <Col className='col-lg-2 col-xs-4' style={{margin:0,padding:10,fontSize:13}}>
        <Link to="/registerpro" style={{color:'white',textDecoration:'none'}}>Search Jobs</Link><br/>
        <Link to="/registeruser" style={{color:'white',textDecoration:'none'}}>Hire a Professional</Link><br/>
        <Link to="/quotes" style={{color:'white',textDecoration:'none'}}>Quotes</Link><br/>
        <Link to="/helpandsupport" style={{color:'white',textDecoration:'none'}}>Help and Support</Link><br/>
        <Link to="/contactus" style={{color:'white',textDecoration:'none'}}>Contact Us</Link><br/><br/>
      </Col>
      <Col className='col-lg-2 col-xs-4' style={{margin:0,padding:10,fontSize:13}}>
        <Link to="/affiliatepolicy" style={{color:'white',textDecoration:'none'}}>Affiliate Policy</Link><br/>
        <Link to="/termsandcondition" style={{color:'white',textDecoration:'none'}}>Terms and Conditions</Link><br/>
        <Link to="/privacypolicy" style={{color:'white',textDecoration:'none'}}>Privacy POLICY</Link><br/>
        <Link to="/copyrightpolicy" style={{color:'white',textDecoration:'none'}}>Copyright POLICY</Link><br/>
        <Link to="/codeofconduct" style={{color:'white',textDecoration:'none'}}>Code of Conduct</Link><br/><br/>
      </Col>
      <Col className='col-lg-2 col-xs-4' style={{margin:0,padding:10,fontSize:13}}>
        <Link to="/feesandcharges" style={{color:'white',textDecoration:'none'}}>Fees and charges</Link><br/>
        <Link to="/investor" style={{color:'white',textDecoration:'none'}}>Investor</Link><br/>
        <Link to="/sitemap" style={{color:'white',textDecoration:'none'}}>Sitemap</Link><br/>
        <Link to="/disclaimer" style={{color:'white',textDecoration:'none'}}>Disclaimer</Link><br/>
        <Link to="/helpandsupport" style={{color:'white',textDecoration:'none'}}>Help And Support</Link><br/>
      </Col>
      <Col className='col-lg-4 col-xs-8' style={{margin:0,padding:10,textAlign:'justify',marginBottom:-10,fontSize:13}}>
      <p style={{fontSize:12,lineHeight:.3, marginTop:10}}>Registered Users : {user}</p>

      <p style={{fontSize:12,lineHeight:.3}}>Registered Professionals : {pro}</p>

      <p style={{fontSize:12,lineHeight:.3}}>Orders Completed : 1</p>
      <p style={{fontSize:10, color:''}}>OYE is a registered trademark of oyeconnect.com <br/>and Oyeconnecting Private Limited copyright@2020 oyeconnect.com</p>
      </Col>
    </Row>
  )
}

export default Footer