import React from 'react'
import {  Link} from "react-router-dom";
import HoverImage from "react-hover-image";

import Add from '../image/add1.png'
import AddHover from '../image/add2.png'
import Order from '../image/order1.png'
import OrderHover from '../image/order2.png'
import Home from '../image/home1.png'
import HomeHover from '../image/home2.png'
import Refer from '../image/referr1.png'
import ReferHover from '../image/referr2.png'
import Setting from '../image/setting1.png'
import SettingHover from '../image/setting2.png'


const FooterAuth = () => {
  return (
    <div>
    <div className="compview"></div>
    <div className="mobileview" style={{position:'fixed',bottom:0,width:'100%',margin:0,padding:0}}>
        <div className="row" style={{position:'absolutee',width:'100%',backgroundColor:'#EBECEC',margin:0,padding:0}}>
            <div className="col-2" style={{padding:10,margin:0}}>
            <Link to="/job" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Add} hoverSrc={AddHover}/></Link>
            </div>
            <div className="col-2" style={{padding:10,margin:0,marginLeft:10}}>
            <Link to="/order" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Order} hoverSrc={OrderHover}/></Link>
            </div>
            <div className="col-4" style={{padding:0,margin:0}}>
                <div className="row" style={{padding:0,margin:0,alignItems:'center'}}>
                    <div className="col-3" style={{padding:0,margin:0}}></div>
                    <div className="col-6" style={{padding:10,margin:0,marginLeft:-10}}>
                    <Link to="/" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Home} hoverSrc={HomeHover}/></Link>
                    </div>
                    <div className="col-3" style={{padding:0,margin:0}}></div>
                </div>          
            </div>
            <div className="col-2" style={{padding:10,margin:0,marginLeft:-20}}>
            <Link to="/referral" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Refer} hoverSrc={ReferHover}/></Link>
            </div>
            <div className="col-2" style={{padding:10,margin:0,marginLeft:10}}>
            <Link to="/setting" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Setting} hoverSrc={SettingHover}/></Link>
            </div>
        </div>
    </div>
    </div>
  )
}

export default FooterAuth