
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminMenu from '../componentsmall/AdminMenu'
import React,{useState,useEffect} from 'react';
import axios from "axios";

function AdminPremium(){
    const [industry,setIndustry] = useState('');
    const [town,setTown] = useState('');
    const [state,setState] = useState('');
    const [category,setCategory] = useState('');
    const [premium,setPremium] = useState([]);

    const [title,setTitle] = useState('');
    const [dataindustry,setDataIndustry] = useState([]);
    const [datatown,setDataTown] = useState([]);
    const [datastate,setDataState] = useState([]);
    const [datacategory,setDataCategory] = useState([]);
    const [datefrom,setDatefrom] = useState('');
    const [dateto,setDateto] = useState('');
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      axios.get ("https://pitch-innovative-cardboard.glitch.me/api/v1/industry")
      .then((res) => setDataIndustry(res.data));
      setIsLoading(false);
    }, [])
    
    useEffect(() => {
    axios.get ("https://pitch-innovative-cardboard.glitch.me/api/v1/category")
    .then((res) => setDataCategory(res.data));
    setIsLoading(false);
  }, [])

    useEffect(() => {
    axios.get ("https://pitch-innovative-cardboard.glitch.me/api/v1/state")
    .then((res) => setDataState(res.data));
    setIsLoading(false);
  }, [])

  useEffect(() => {
    axios.get ("https://pitch-innovative-cardboard.glitch.me/api/v1/town")
    .then((res) => setDataTown(res.data));
    setIsLoading(false);
  }, [])
  useEffect(() => {
    axios.get ("https://pitch-innovative-cardboard.glitch.me/api/v1/premium")
    .then((res) => setPremium(res.data));
    setIsLoading(false);
  }, [])

  const postData = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("title", title);
    myForm.set("industry", industry);
    myForm.set("category", category);
    myForm.set("state", state);
    myForm.set("town", town);
    myForm.set("datefrom", datefrom);
    myForm.set("dateto", dateto);
    myForm.append("image", image);
    axios.post('https://pitch-innovative-cardboard.glitch.me/api/v1/premium',(myForm))
    .then(res => {window.location.reload();})
    .catch(err => console.log(err))
  };

  const premiumImageChange = (e) => {
    const file = e.target.files[0];
    
    const reader = new FileReader();
    
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
        setImagePreview(reader.result); 
      }
    };

    reader.readAsDataURL(file);
  };

  
  const deletePost = (_id) => {
    axios.delete(`https://pitch-innovative-cardboard.glitch.me/api/v1/industry/${_id}`)
    .then(res => {window.location.reload();})
    .catch(err => console.log(err))
      };

  return (
    <>
    {isLoading ? (
      <img src="loading.gif" alt="Loading" />
    ) : (
    <Row style={{margin:0,padding:0, marginTop:10}}>
      <Col className="col-lg-" style={{margin:0,padding:10}}>
          <AdminMenu/>
       </Col>
       <Col className="col-lg-10" style={{margin:0,padding:25}}>
        <Row style={{margin:0,padding:0}}>
            <Col className="col-lg-9" style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
                <Row style={{backgroundColor:'black',color:'white',padding:10,borderRadius:10,marginTop:-10,}}>
                    <Col className="col-1" style={{}}>Title</Col>
                    <Col className="col-5" style={{}}>Image</Col>
                    <Col className="col-2" style={{}}>State / Town</Col>
                    <Col className="col-2" style={{}}>Industry / Category</Col>
                    <Col className="col-1" style={{}}>Date from - to</Col>
                    <Col className="col-1" style={{}}>position</Col>       
                </Row>
                {premium && premium.map((item) => (
                <Row style={{padding:10, borderBottom:'1px solid grey',alignItems: 'center',justifyContent: 'center'}}>
                    <Col className="col-1" style={{padding:'5px',textAlign:'left'}}><b>{item.title}</b></Col>
                    <Col className="col-5" style={{padding:'5px',textAlign:'left'}}><img src={item.url} style={{width:'100%'}}/></Col>
                    <Col className="col-2" style={{padding:'5px',textAlign:'left'}}>{item.state.name} / {item.town.name}</Col>
                    <Col className="col-2" style={{padding:'5px',textAlign:'left'}}>{item.industry.name} / {item.category.name}</Col>
                    <Col className="col-1" style={{padding:'5px',textAlign:'left'}}>{item.datefrom} - {item.dateto}</Col>
                    <Col className="col-1" style={{padding:'5px',textAlign:'left'}}>{item.active}</Col>

                </Row>
            ))}
            </Col>
            <Col className="col-lg-3" style={{margin:0,paddingLeft:15}}>
                <form>
                    <label>Create Premium</label>
                    <input type="text" placeholder="title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                    
                    <select onChange={(e) => setIndustry(e.target.value)} 
                    style={{fontSize:11,width:'100%',padding:5,borderRadius:5,marginTop:10,borderWidth:1,borderColor:'grey'}}>
                    <option value="" style={{}}>Choose Industry</option>
                    {dataindustry && dataindustry.map((item) => (
                    <option key={item._id} value={item._id} style={{width:'100%'}}>{item.name}</option>
                    ))}
                    </select>

                    <select onChange={(e) => setCategory(e.target.value)} 
                    style={{fontSize:11,width:'100%',padding:5,borderRadius:5,marginTop:10,borderWidth:1,borderColor:'grey'}}>
                    <option value="" style={{}}>Choose Category</option>
                    {datacategory && datacategory.map((item) => (
                    <option key={item._id} value={item._id} style={{width:'100%'}}>{item.name}</option>
                    ))}
                    </select>

                    <select onChange={(e) => setState(e.target.value)} 
                    style={{fontSize:11,width:'100%',padding:5,borderRadius:5,marginTop:10,borderWidth:1,borderColor:'grey'}}>
                    <option value="" style={{}}>Choose State</option>
                    {datastate && datastate.map((item) => (
                    <option key={item._id} value={item._id} style={{width:'100%'}}>{item.name}</option>
                    ))}
                    </select>

                    <select onChange={(e) => setTown(e.target.value)} 
                    style={{fontSize:11,width:'100%',padding:5,borderRadius:5,marginTop:10,borderWidth:1,borderColor:'grey'}}>
                    <option value="" style={{}}>Choose Town</option>
                    {datatown && datatown.map((item) => (
                    <option key={item._id} value={item._id} style={{width:'100%'}}>{item.name}</option>
                    ))}
                    </select>

                    <input type="date" style={{fontSize:12, width:'100%',marginTop:10,padding:5,borderRadius:5,border:'1px solid grey'}} placeholder="Date From" value={datefrom} onChange={(e) => setDatefrom(e.target.value)}/>
                    <input type="date" style={{fontSize:12, width:'100%',marginTop:10,padding:5,borderRadius:5,border:'1px solid grey'}} placeholder="Date Till" value={dateto} onChange={(e) => setDateto(e.target.value)}/>
                    <input type="file" style={{fontSize:12, width:'100%',marginTop:10,padding:5,borderRadius:5,border:'1px solid grey'}} name="avatar" accept="image/*" onChange={premiumImageChange} />

                    <button style={{width:'100%',marginTop:10,padding:5,borderRadius:5,border:'1px solid grey',backgroundColor:'#393185',color:'white',fontSize:15}} onClick={postData}>POST</button>
                </form>
                <br></br>
                <div id="createProductFormImage" style={{height:'120px'}}>
                  {imagePreview && <img src={imagePreview} alt="Product Preview" style={{height:'100%'}}/>}
                </div>
            </Col>
        </Row>
      </Col>
    </Row>
    )}
    </>
  );
};

export default AdminPremium