import React, {  useState, useEffect, useContext} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import {  Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { clearErrors,login, loadUser } from "../Actions/User";
import { useAlert } from "react-alert";
import axios from 'axios';


const Login = () => {

  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();
  const [isRevealPassword] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const alert = useAlert();

  const { error, message, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );


  const loginHandler = (e) => {
    e.preventDefault();
    if (mobile.length !== 10) {
      alert.error('Mobile number must be 10 digits');
      return;
    }
  
    if (mobile === '' || password === '') {
      alert.error('Please fill in your credentials');
      return;
    }

    e.preventDefault();
    axios.post('/api/v1/user/login',{mobile,password})
    .then(res => {window.location.href = "/";})
    .catch(err => console.log(err))
    };
    
 
  
    useEffect(() => {
      if (error) {
        alert.error(error);
        dispatch(clearErrors());
      }
      
      if (isAuthenticated) {
        alert.success(message);
        dispatch(clearErrors());
        navigate("/setting");
      }
    }, [dispatch, error, alert, isAuthenticated]);
  

    
  return (
    <form className="loginform" onSubmit={loginHandler} style={{margin:0,padding:10}}>
    <h2>LOGIN</h2>
    <TextField
      required
      id="outlined-required"
      label="mobile"
      placeholder="Enter Mobile Number"
      value={mobile}
      onChange={e => setMobile(e.target.value)}
      style={{width:'100%', marginTop:10}}
    />
    <TextField
      required
      id="outlined-required"
      label="Password"
      placeholder="Enter Password"
      type={isRevealPassword ? "text" : "password"}
      value={password}
      onChange={e => setPassword(e.target.value)}
      style={{width:'100%', marginTop:30}}
    />
      <Row style={{marginTop:10,padding:0,textAlign:'left',color:'#003C69'}}>
        <Col className="col-lg-12">
        <Link to="/forgotpassword" style={{fontSize:14,textDecoration:'none'}}>forgot password?</Link><br/>
        </Col>
        <Col className="col-lg-12" style={{marginTop:10,}}>
            <text style={{fontSize:14,color:'grey'}}>havent Registered yet ? </text><Link to="/registeruser" style={{fontSize:14,textDecoration:'none'}}>Register here</Link>
        </Col>
      </Row>
    <button type="submit" className="bg-primary-blue font-medium py-2 rounded text-white w-full" 
    style={{ marginTop:10,backgroundColor:'#004274',width:'100%'}}>Login</button>
    </form>
  )
}

export default Login