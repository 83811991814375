import React from 'react'
import CreateAds from '../component/CreateAds'
import MyAds from '../component/MyAds'

const ProAds = () => {
  return (
    <div className="row" style={{margin:0,padding:10}}>
      <div className="col-lg-3" style={{margin:0}}>
        <CreateAds/>
      </div>
      <div className="col-lg-9" style={{margin:0}}>
        <MyAds/>
      </div>
    </div>
  )
}

export default ProAds