import axios from "axios";

export const getProNew = () => async (dispatch) => {
  try {
    dispatch({
      type: "getProNewRequest",
    });

    const { data } = await axios.get(`/api/v1/user/getactivepronew`);
    dispatch({
      type: "getProNewSuccess",
      payload: {
        pro: data.pro,
      },
    });
  } catch (error) {
    dispatch({
      type: "getProNewFailure",
      payload: error.response.data.message,
    });
  }
};

