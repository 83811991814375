
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React,{useState,useEffect} from 'react';
import axios from "axios";

function AdminState(){
    const [state,setstate] = useState([]);
    
    const [name,setName] = useState('');

useEffect(() => {
    axios.get ("/api/v1/state")
    .then((res) => setstate(res.data));
  }, [])

  const postData = (e) => {
    e.preventDefault();
    axios.post('/api/v1/state',{name})
    .then(res => {window.location.reload();})
    .catch(err => console.log(err))
  };

  const deletePost = (_id) => {
    axios.delete(`/api/v1/state/${_id}`)
    .then(res => {window.location.reload();})
    .catch(err => console.log(err))
      };

return (
        <Row style={{margin:0,padding:0}}>
            <Col className="col-lg-9" style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
                <tr style={{backgroundColor:'black',color:'white'}}>
                    <td>Name</td>
                    <td>Delete</td>        
                </tr>
                {state.map(item => (
                
                <tr style={{padding:'5px'}}>
                    <td style={{padding:'5px'}}>{item.name}</td>
                    <td style={{padding:'5px'}}>
                    <button style={{border:0,backgroundColor:"red",color:"white"}}  
                    key={item._id} onClick={() => deletePost(item._id)}>Remove</button></td>
                </tr>
            ))}
            </Col>
            <Col className="col-lg-3" style={{margin:0,paddingLeft:15}}>
                <form>
                    <label>Create State</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                    <button onClick={postData}>POST</button>
                </form>
            </Col>
        </Row>
  )
}

export default AdminState