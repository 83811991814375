import React, { useState } from 'react';
import axios from 'axios';

const CreateFlexslider = () => {
  const [formData, setFormData] = useState({
    name: '',
    image: null,
    code: 'Mobile', // Default to 'Mobile'
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Read the file as a data URL and set it in the formData
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({
          ...formData,
          image: event.target.result, // Set the image as a base64-encoded string
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCodeChange = (e) => {
    const code = e.target.value;
    setFormData({
      ...formData,
      code,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/v1/flexslider/', formData);
      console.log(response.data);
      // Handle success, clear form, show success message, etc.
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4>Create About Us</h4>
      <div>
        <label htmlFor="name">Name:</label><br />
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
      </div>
      <div>
        <label htmlFor="code">Platform:</label><br />
        <select
          id="code"
          name="code"
          value={formData.code}
          onChange={handleCodeChange}
        >
          <option value="Mobile">Mobile</option>
          <option value="Computer">Computer</option>
        </select>
      </div>
      <div>
        <label htmlFor="image">Image:</label><br />
        <input
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default CreateFlexslider;