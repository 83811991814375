import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { loadUser} from "../Actions/User";
import { getJobWithState} from "../Actions/Job";
import JobContainer from "../componentsmall/JobContainer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Previous
    </div>
  );
};

// Custom next arrow component
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Next
    </div>
  );
};

const JobSameState = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const settings1 = {
        dots: false,
        infinite: true,
        slidesToShow : 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 20000,
        autoplaySpeed : 1000,
        cssEase: "linear",
        prevArrow: <PrevArrow />, // Custom previous arrow component
      nextArrow: <NextArrow />
      };

const { user, loading: userLoading } = useSelector((state) => state.user);
const { loading, error, jobs, totalJobs } = useSelector((state) => state.Job);

const stateId = user.state && user.state._id;

const {
    error: likeError,
    message,
    loading: deleteLoading,
  } = useSelector((state) => state.likeJob);

  const dispatch = useDispatch();
const alert = useAlert();
 

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    if (stateId) {
      dispatch(getJobWithState(stateId));
    }
  }, [dispatch, stateId]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="jobs" style={{margin:0,padding:0}}>

        <div className="row" style={{margin:0,padding:0}}>
        {jobs && jobs.length > 0 ? (
          <div className="col-12" style={{margin:0}}>
            <p>Jobs from {user.state.name}- ({totalJobs} resuts)</p>
          </div>
        ) : null}
        </div>

        <div className="row" style={{margin:0,padding:0,marginTop:-10}}>
        {jobs && jobs.length >= 4 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 4 : 1}>
            {jobs && jobs.length > 0 ? (
            jobs.map((job) => (
            <JobContainer
                key={job._id}
                id={job._id}
                name={job.name}
                description={job.description}
                statetown={`${job.town ? job.town.name: ''}, ${job.state ? job.state.name: ''}`}
                industry={job.industry ? job.industry.name : ''}
                category={job.category ? job.category.name : ''}
                image={job.image.url}

                price={job.price}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            jobs.map((job) => (
                <div className="col-lg-3 sm-12" style={{margin:0,padding:0}}>
                <div className="row" style={{margin:0,padding:0}} >
              <JobContainer 
                key={job._id}
                id={job._id}
                name={job.name}
                description={job.description}
                statetown={`${job.town ? job.town.name: ''}, ${job.state ? job.state.name: ''}`}
                industry={job.industry ? job.industry.name : ''}
                category={job.category ? job.category.name : ''}
                image={job.image.url}
                price={job.price}
              />
              </div>
              </div>
            ))
          )}
        </div>

    </div>

  )
}

export default JobSameState