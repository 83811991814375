import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CreateAboutUs from "../component/Admin/CreateAboutUs"
import UpdateAboutUs from "../component/Admin/UpdateAboutUs"

const AdminAboutUs = () => {
  const [aboutUsEntries, setAboutUsEntries] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);

  useEffect(() => {
    // Fetch all "about us" entries when the component mounts
    const fetchAboutUsEntries = async () => {
      try {
        const response = await axios.get('/api/v1/webcategory/viewaboutus/');
        setAboutUsEntries(response.data.data);
      } catch (error) {
        console.error(error);
        // Handle error, show error message, etc.
      }
    };

    fetchAboutUsEntries();
  }, []);

  const handleUpdate = (entryId) => {
    setSelectedEntryId(entryId);
  };


  const handleDelete = async (entryId) => {
    // Send a delete request to the backend
    try {
      await axios.delete(`/api/v1/webcategory/deleteaboutus/${entryId}`);
      // Remove the deleted entry from the local state
      setAboutUsEntries(aboutUsEntries.filter((entry) => entry._id !== entryId));
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <div className="row">
      <div className="col-3">
      {selectedEntryId ? (
          <UpdateAboutUs entryId={selectedEntryId}  />
        ) : (
          <CreateAboutUs />
        )}
      </div>
      <div className="col-9">
        <h2>About Us Entries</h2>
        <table>
          <thead>
            <tr>
              <th>Heading</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {aboutUsEntries.map((entry) => (
              <tr key={entry._id}>
                <td>{entry.heading}</td>
                <td>{entry.description}</td>
                <td>
                  <button onClick={() => handleUpdate(entry._id)}>Update</button>
                  <button onClick={() => handleDelete(entry._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminAboutUs;