import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadUser, logout, updateUser } from '../Actions/User';
import axios from 'axios'
import { useAlert } from "react-alert";
import Select from 'react-select';
import {  useNavigate } from 'react-router-dom';

const UserSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);

  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [mobile] = useState(user.mobile);
  const [email, setEmail] = useState(user.email);
  const [referralcode] = useState(user.referralcode);
  const [gender, setGender] = useState(user.gender);
  const [dob, setDob] = useState(user.dob ? new Date(user.dob).toISOString().split('T')[0] : '');
  
  const [towns, setTowns] = useState([]);
  const [town, setTown] = useState(user.town ? user.town._id : '');
  
  const [states, setStates] = useState([]);
  const [state, setState] = useState(user.state && user.state._id ? user.state._id : '');

  const [address, setAddress] = useState(user.address);
  const [pincode, setPincode] = useState(user.pincode);

  const [profilepic, setProfilepic] = useState("");
  const [profilepicPrev, setProfilepicPrev] = useState(user.profilepic.url);


  const stateOptions = states.map(state => ({
    value: state._id,
    label: state.name
  }));

  const townOptions = towns.map(town => ({
    value: town._id,
    label: town.name
  }));

  
  useEffect(() => {
    // Fetch states from the server
    axios.get('/api/v1/state')
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.error('Error fetching states:', error);
      });
  }, [user.state]);
  
  useEffect(() => {
    // Fetch towns based on the selected state
    if (state) {
      axios.get(`/api/v1/town/state/${state}`)
        .then(response => {
          setTowns(response.data);
        })
        .catch(error => {
          console.error('Error fetching towns:', error);
        });
    }
  }, [state]); // Fetch towns when the selected state changes

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
  
    if (e.target.files.length === 0) {
      return; // No file selected, do nothing
    }
    
    reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          const maxSize = Math.max(img.width, img.height);
          canvas.width = maxSize;
          canvas.height = maxSize;
  
          let x = 0;
          let y = 0;
          let width = img.width;
          let height = img.height;
  
          if (img.width > img.height) {
            x = (img.width - img.height) / 2;
            width = img.height;
          } else if (img.height > img.width) {
            y = (img.height - img.width) / 2;
            height = img.width;
          }
  
          ctx.drawImage(img, x, y, width, height, 0, 0, maxSize, maxSize);
  
          const croppedImage = canvas.toDataURL('image/jpeg');
  
          setProfilepicPrev(croppedImage);
          setProfilepic(croppedImage);
        };
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit= async (e) => {
    e.preventDefault();
    const updatedDOB = dob ? new Date(dob) : null;
    await dispatch(updateUser(firstname, lastname, profilepic,email, gender, dob, town, state, address, pincode));
    dispatch(loadUser());
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logout());
 // Use the navigate function to go to a different page after logging out
  };

  useEffect(() => {
    console.log('user.town:', user.town);
  }, [user.town]);

  const profileCompletionPercentage =
    (user.town && user.state ? 2 : 1) * 50;

  return (
    <div style={{padding:20}}>
      {!user.town && !user.state ? (
        <div className="alert alert-danger" style={{fontSize:13}}>
          Your profile is not ready. Please fill in the necessary information to complete your profile and start using OyeConnect.
        </div>
      ):(<div className="alert alert-danger" style={{fontSize:13}}>
      Your profile is complete. You can now navigate to home page by clicking home in header.
    </div>
    )
    }
      <div className="progress" style={{ marginTop: '20px' }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${profileCompletionPercentage}%` }}
          aria-valuenow={profileCompletionPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {`${profileCompletionPercentage}% Complete`}
        </div>
      </div><br></br>
      <div className="row">
        <div className="col-3" style={{padding:20,marginTop:-20,}}>
          <div className="row" style={{backgroundColor:'#EBECEC',borderRadius:20,height:420,padding:20}}>
          Personal Setting<br></br>
          <label style={switchStyles}>
            <input type="checkbox" style={{ display: 'none' }} />
            <span style={sliderStyles}>
              <span style={sliderInnerStyles}></span>
            </span>
          </label>
          Update Mobile Number<br></br>
          Change Password<br></br>
          <button type="submit" onClick={handleLogout}
          style={{padding:0,alignItems:'top',textAlign:'left',border:'none',}}>
           Logout
          </button>
          </div>
        </div>
        <div className="col-9" style={{height:420, overflowY:'scroll'}}>
          <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-8">
          <h2>User Settings</h2>  
            <div className="form-group">
              <label htmlFor="firstname">Firstname</label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                name="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div><br></br>
            <div className="form-group">
              <label htmlFor="lastname">Lastname</label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                name="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
            </div>
            <div className="col-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{position: 'relative', width: '200px',height: '200px',borderRadius: '50%',overflow: 'hidden', marginRight: '20px',}}>
                <img src={profilepicPrev} alt="Profile" style={{ width: '100%', height: '100%',objectFit: 'cover', }} />
                <input type="file"  accept="image/*"onChange={handleImageChange} style={{  position: 'absolute',   top: 0,  left: 0,  width: '100%',  height: '100%',  opacity: 0,  cursor: 'pointer', }} />
              </div>
            </div>
            </div><br></br>
            <div className="form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <input
                type="mobile"
                className="form-control"
                id="mobile"
                name="mobile"
                value={mobile}
                disabled
              />
            </div><br></br>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div><br></br>
            <div className="form-group">
              <label htmlFor="referralcode">Referral Code</label>
              <input
                type="referralcode"
                className="form-control"
                id="referralcode"
                name="referralcode"
                value={referralcode}
                disabled
              />
            </div><br></br>
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                className="form-control"
                id="gender"
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                 <option value="male">Male</option>
                 <option value="female">Female</option>
              </select>
            </div><br></br>
            <div className="form-group">
              <label htmlFor="dob">Date of Birth</label>
              <input
              type="date"
              className="form-control"
              id="dob"
              name="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
            </div><br></br>
            <div className="form-group">
              <label htmlFor="state">State</label>
              <Select
                options={stateOptions}
                value={stateOptions.find(option => option.value === state)}
                onChange={selectedOption => setState(selectedOption.value)}
              />
            </div><br></br>
            <div className="form-group">
              <label htmlFor="town">Town</label>
              <Select
                options={townOptions}
                value={townOptions.find(option => option.value === town)}
                onChange={selectedOption => setTown(selectedOption.value)}
              />
            </div><br></br>
            
            <div className="form-group">
              <label htmlFor="address">Full Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div><br></br>
            <div className="form-group">
              <label htmlFor="pincode">Pincode</label>
              <input
                type="text"
                className="form-control"
                id="pincode"
                name="pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div><br></br>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </form>
      </div>
      </div>
    </div>
  );
};

const switchStyles = {
  display: 'inline-block',
  position: 'relative',
  width: '40px',
  height: '20px'
};

const sliderStyles = {
  position: 'absolute',
  cursor: 'pointer',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  transition: '0.4s',
  borderRadius: '34px'
};

const sliderInnerStyles = {
  position: 'absolute',
  content: '',
  height: '18px',
  width: '18px',
  bottom: '1px',
  backgroundColor: 'white',
  transition: '0.4s',
  borderRadius: '50%'
};

export default UserSetting;
