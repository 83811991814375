import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminMenu from '../componentsmall/AdminMenu'
import axios from "axios";

function AdminTown(){
    const [town,settown] = useState([]);
    const [Catstate,setstate] = useState([]);
    
    const [name,setName] = useState('');
    const [state,setCatstate] = useState('');

    const [isButtonClicked, setIsButtonClicked] = useState(false);

useEffect(() => {
    axios.get ("/api/v1/state")
    .then((res) => setstate(res.data));
    }, [])
    
useEffect(() => {
    axios.get ("/api/v1/town")
    .then((res) => settown(res.data));
  }, [])

  const postData = (e) => {
    e.preventDefault();
    setIsButtonClicked(true); // Set the button click state to true
    axios
    .post('/api/v1/town', { name, state })
    .then(() => {
      axios.get('/api/v1/state').then((res) => setstate(res.data));
      axios.get('/api/v1/town').then((res) => settown(res.data));
    })
    .catch((err) => {
      setIsButtonClicked(false); // Reset button click state in case of error
      console.log(err);
    })
    .finally(() => {
      setIsButtonClicked(false); // Reset button click state after API call, regardless of success or error
    });
};


  const deletePost = (_id) => {
    axios.delete(`/api/v1/town/${_id}`)
    .then(res => {window.location.reload();})
    .catch(err => console.log(err))
      };

  return (
    <Row style={{margin:0,padding:0, marginTop:10}}>
      <Col className="col-lg-" style={{margin:0,padding:10}}>
          <AdminMenu/>
       </Col>
      <Col className="col-lg-10" style={{margin:0,padding:15}}>
        <Row style={{margin:0,padding:0,}}>
        <Col className="col-lg-8" style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
                <tr style={{backgroundColor:'black',color:'white'}}>
                    <td style={{width:'40%'}}>&nbsp;&nbsp;Town</td>
                    <td style={{width:'40%'}}>&nbsp;&nbsp;State</td>
                    <td style={{width:'20%'}}>&nbsp;&nbsp;Delete</td>        
                </tr>
                {town && town.map((item) => (
                
                <tr style={{padding:'5px'}}>
                    <td style={{padding:'5px',width:'40%'}}>{item.name}</td>
                    {item.state.map((state) => (
                    <td style={{padding:'5px',width:'40%'}}>{state.name}</td>
                    ))}
                    <td style={{padding:'5px',width:'20%'}}>
                    <button style={{border:0,backgroundColor:"red",color:"white"}}  
                    key={item._id} onClick={() => deletePost(item._id)}>Remove</button></td>
                </tr>
            ))}
            </Col>
            <Col className="col-lg-4" style={{margin:0,paddingLeft:15}}>
                <form>
                    <label>Create Town</label><br></br>

                    <input type="text" placeholder="town" value={name} onChange={(e) => setName(e.target.value)}
                    style={{fontSize:11,width:'100%',padding:10,borderRadius:10,borderWidth:1,borderColor:'grey'}}/>
                    <p></p>
                    
                    <select onChange={(e) => setCatstate(e.target.value)} 
                    style={{fontSize:11,width:'100%',padding:10,borderRadius:10,borderWidth:1,borderColor:'grey'}}>
                    <option value="">Choose State</option>
                    {Catstate && Catstate.map((item) => (
                    <option key={item._id} value={item._id} style={{width:'100%'}}>{item.name}</option>
                    ))}
                    </select>
                    <p></p>
                    <button
                      onClick={postData}
                      style={{
                        fontSize: 11,
                        width: '100%',
                        padding: 10,
                        borderRadius: 10,
                        backgroundColor: isButtonClicked ? 'green' : '#004274', // Change background color when clicked
                        color: 'white',
                      }}
                    >
                      POST
                    </button>
                </form>
            </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AdminTown