import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Disclaimer = () => {
  const [DisclaimerEntries, setDisclaimerEntries] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);

  useEffect(() => {
    const fetchDisclaimerEntries = async () => {
      try {
        const response = await axios.get('/api/v1/webcategory/viewdisclaimer/');
        setDisclaimerEntries(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDisclaimerEntries();
  }, []);

  return (
    <div className="row" style={{padding:0,margin:0}}>
      {DisclaimerEntries.map((entry) => (
      <div className="col-12"style={{margin:0,padding:15,fontSize:'14px'}}>
        <div className="row">
          <div className={`col-${entry.image && entry.image.url ? '9' : '12'}`} style={{margin:0,padding:0,textAlign:'justify'}}>
            <h2 style={{padding:'5px'}}>{entry.heading}</h2>
            {entry.description && ( <>
            <p style={{padding:'5px'}}>{entry.description}</p>
            </> )}
            {entry.subheading.map((subheading) => (
                <div className="" style={{textAlign:'justify'}}>
                  {subheading.name && ( <>
                  <h5 style={{ padding: '5px' }}>{subheading.name}</h5>
                  </> )}
                  {subheading.description && ( <>
                  <p style={{padding:'5px'}}>{subheading.description}</p>
                  </> )}
                </div>
            ))}
          </div>
          {entry.image && entry.image.url && (
          <div className="col-3" style={{ padding: 0, margin: 0 }}>
              <img style={{ padding: '5px', width: '100%' }} src={entry.image.url} alt="About Us" />
          </div>
          )}
        </div>
      </div>
      ))}
    </div>
  );
};

export default Disclaimer;