import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { createNewAds } from "../Actions/Ads";
import { loadUser, getMyAds} from "../Actions/User";
import addads from "../image/uploadimage.png";

const CreateAds = () => {
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");

  const [states, setStates] = useState([]);
  const [state, setState] = useState("");

  const [towns, setTowns] = useState([]);
  const [town, setTown] = useState("");
  
  const [industries, setIndustries] = useState([]);
const [industry, setIndustry] = useState("");

const [categories, setCategories] = useState([]);
const [category, setCategory] = useState("");

const industryOptions = industries.map(industry => ({
  value: industry._id,
  label: industry.name
}));

const categoryOptions = categories.map(category => ({
  value: category._id,
  label: category.name
}));

  const stateOptions = states.map(state => ({
    value: state._id,
    label: state.name
  }));

  const townOptions = towns.map(town => ({
    value: town._id,
    label: town.name
  }));


  const { loading, error, message } = useSelector((state) => state.likeAds);
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    // Fetch states from the server
    axios.get('/api/v1/state')
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.error('Error fetching states:', error);
      });
  },[]);
  
  useEffect(() => {
    // Fetch towns based on the selected state
    if (state) {
      axios.get(`/api/v1/town/state/${state}`)
        .then(response => {
          setTowns(response.data);
        })
        .catch(error => {
          console.error('Error fetching towns:', error);
        });
    }
  }, [state])

  useEffect(() => {
    // Fetch industries from the server
    axios.get('/api/v1/industry')
      .then(response => {
        setIndustries(response.data);
      })
      .catch(error => {
        console.error('Error fetching industries:', error);
      });
  },[]);
  
  useEffect(() => {
    // Fetch categories based on the selected industry
    if (industry) {
      axios.get(`/api/v1/category/industry/${industry}`)
        .then(response => {
          setCategories(response.data);
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    }
  }, [industry])
  
  const handleImageChange = (e) => {
    if (e.target.files.length === 0) {
      return; // No file selected, do nothing
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;

        let x = 0;
        let y = 0;
        let width = img.width;
        let height = img.height;

        if (img.width > img.height) {
          x = (img.width - img.height) / 2;
          width = img.height;
        } else if (img.height > img.width) {
          y = (img.height - img.width) / 2;
          height = img.width;
        }

        ctx.drawImage(img, x, y, width, height, 0, 0, maxSize, maxSize);

        const croppedImage = canvas.toDataURL("image/jpeg");

        setImage(croppedImage);
      };
    };

    reader.readAsDataURL(file);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    await dispatch(createNewAds(name,description,price,state,town,category,industry,image));
    dispatch(getMyAds());
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="newPost" >
      <form className="newPostForm" onSubmit={submitHandler} style={{height:520,overflowY:'scroll',paddingRight:15}}>
        <h3 className="h3">Create New Advertisement</h3>

        <div style={{position: 'relative', width: '200px',height: '200px',borderRadius: '10%',overflow: 'hidden', marginRight: '20px',}}>
                  {image ? (
                  <img src={image} alt="adsimage" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                  <img src={addads} alt="adsimage" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                )}
                <input type="file"  accept="image/*"onChange={handleImageChange} style={{  position: 'absolute',   top: 0,  left: 0,  width: '100%',  height: '100%',  opacity: 0,  cursor: 'pointer', }} />
              </div>

        <div className="form-group">
          <br></br>
          <input
            type="text"
            className="form-control"
            placeholder="Title of advertisment"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div><br></br>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            style={{height:80}}
            placeholder="Description (Max 50 Words)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div><br></br>
        <div className="form-group">
          <input
            type="Number"
            className="form-control"
            placeholder="Amount"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div><br></br>
        <div className="form-group">
              <label htmlFor="state">State</label>
              <Select
                options={stateOptions}
                value={stateOptions.find(option => option.value === state)}
                onChange={selectedOption => setState(selectedOption.value)}
              />
        </div><br></br>
        <div className="form-group">
            <label htmlFor="town">Town</label>
              <Select
                options={townOptions}
                value={townOptions.find(option => option.value === town)}
                onChange={selectedOption => setTown(selectedOption.value)}
              />
        </div><br></br>
        <div className="form-group">
              <label htmlFor="industry">Industry</label>
              <Select
                options={industryOptions}
                value={industryOptions.find(option => option.value === industry)}
                onChange={selectedOption => setIndustry(selectedOption.value)}
              />
        </div><br></br>
        <div className="form-group">
            <label htmlFor="category">category</label>
              <Select
                options={categoryOptions}
                value={categoryOptions.find(option => option.value === category)}
                onChange={selectedOption => setCategory(selectedOption.value)}
              />
        </div><br></br>
        <button className="btn btn-primary" type="submit">
          Post
        </button><br></br>
      </form>
    </div>
  )
}

export default CreateAds