import React,{useState, useEffect} from 'react'
import {  Link} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import HoverImage from "react-hover-image";
import { loadUser } from '../Actions/User';

import logo from '../image/logo.png';
import logofull from '../image/logofull.png';
import Add from '../image/add1.png'
import AddHover from '../image/add2.png'
import Order from '../image/order1.png'
import OrderHover from '../image/order2.png'
import Home from '../image/home1.png'
import HomeHover from '../image/home2.png'
import Refer from '../image/referr1.png'
import ReferHover from '../image/referr2.png'
import Setting from '../image/setting1.png'
import SettingHover from '../image/setting2.png'
import Search from '../image/search1.png'
import SearchHover from '../image/search2.png'
import Contact from '../image/contact1.png'
import ContactHover from '../image/contact2.png'
import Blog from '../image/blog1.png'
import BlogHover from '../image/blog2.png'
import Message from '../image/message1.png'
import MessageHover from '../image/message2.png'
import Notification from '../image/notification1.png'
import NotificationHover from '../image/notification2.png'
import Menu from '../image/menu.png'
import MenuHover from '../image/menuhover.png'
import '../style.css'

const HeaderAuth = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Dispatch the loadUser action to load user data when the component mounts
    dispatch(loadUser());
    
    // ... (rest of your useEffect code)
  }, [dispatch]);

  var [date, setDate] = useState(new Date());
  const [showMenu, setShowMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { isAuthenticated, user } = useSelector((state) => state.user);
  let fullName = user?.firstname && user?.lastname ? `${user.firstname} ${user.lastname}` : "";

  if (fullName.length > 25) {
    fullName = fullName.slice(0, 22) + '...';
  }

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000 )
    return function cleanup() {
      clearInterval(timer)
    }
  });

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleMobileMenuClick = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div>
      <div className="compview" style={{ backgroundColor: '#EBECEC' }}>
        <div className="row" style={{ margin: 0 }}>
          <div className="col-3" style={{ padding: 10, marginLeft: 10 }}>
            <Link to="/"><img alt="logo" src={logofull} style={{ width: '80%' }} /></Link>
          </div>
          <div className="col-6" style={{ marginTop: 20, marginLeft: -10 }}>
            <div className="row" style={{}}>
              <div className="col-1"></div>
              <div className="col-1">
              <Link to="/" style={{color:'grey'}} ><HoverImage style={{width:'100%'}} src={Home} hoverSrc={HomeHover} title="Home" /></Link>
              </div>
              <div className="col-1">
              <Link to="/job" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Add} hoverSrc={AddHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/order" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Order} hoverSrc={OrderHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/referral" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Refer} hoverSrc={ReferHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/search" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Search} hoverSrc={SearchHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/contact" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Contact} hoverSrc={ContactHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/blog" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Blog} hoverSrc={BlogHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/message" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Message} hoverSrc={MessageHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/notification" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Notification} hoverSrc={NotificationHover}/></Link>
              </div>
              <div className="col-1">
              <Link to="/setting" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Setting} hoverSrc={SettingHover}/></Link>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-7" style={{textAlign:'right',fontSize:11,lineHeight:1,marginTop:10}}>
              {user ? (
                  <>
                    Welcome<br></br>
                    <b>{fullName}</b> - &nbsp;
                    {user.role}<br></br>
                    {user.town && user.town.name ? (
                      <>
                        {user.town.name},&nbsp;&nbsp;{user.state.name}<br />
                      </>
                    ) : null}<br></br>
                    <text style={{ fontSize: 10, color: '#003C69' }}> {date.toLocaleDateString()} &nbsp; {date.toLocaleTimeString()}</text><br />
                  </>
                ) : null}
              </div>
              <div className="col-3" style={{marginTop:5,marginLeft:-20}}>
                {user?.profilepic?.url && (
                    <img src={user.profilepic.url} style={{ width: '100%', borderRadius: 30 }} alt="Profile Pic" />
                  )}
              </div>
              <div className="col-2" style={{padding:15,marginTop:5}}>
              <HoverImage style={{width:'100%'}} src={Menu} hoverSrc={MenuHover} onClick={handleMenuClick}/>
              </div>
            </div>
          </div>
        </div>

        {showMenu && (
        <div className="menu-items" style={{position:'fixed',top:40,right:0,textAlign:'right',padding:20,backgroundColor:'#D9DADA',zIndex:11}}>
              <button style={{marginRight:100,textDecoration:'none',border:'none',backgroundColor:'none'}}onClick={handleMenuClick}>x</button>
              <Link to="/aboutus" style={{color:'#003C69',textDecoration:'none'}}>ABOUT US</Link><br></br>
              <Link to="/news" style={{color:'#003C69',textDecoration:'none'}}>NEWS</Link><br></br>
              <Link to="/howitworks" style={{color:'#003C69',textDecoration:'none'}}>HOW IT WORKS</Link><br></br>
              <Link to="/registeruser" style={{color:'#003C69',textDecoration:'none'}}>HIRE A PROFESSIONAL</Link><br></br>
              <Link to="/earnwithus" style={{color:'#003C69',textDecoration:'none'}}>EARN WITH US</Link><br></br>
              <Link to="/affiliatepolicy" style={{color:'#003C69',textDecoration:'none'}}>AFFILIATE POLICY</Link><br></br>
              <Link to="/codeofconduct" style={{color:'#003C69',textDecoration:'none'}}>CODE OF CONDUCT</Link><br></br>
              <Link to="/copyrightpolicy" style={{color:'#003C69',textDecoration:'none'}}>COPYRIGHT POLICY</Link><br></br>
              <Link to="/privacypolicy" style={{color:'#003C69',textDecoration:'none'}}>PRIVACY POLICY</Link><br></br>
              <Link to="/disclaimer" style={{color:'#003C69',textDecoration:'none'}}>DISCLAIMER</Link><br></br>
              <Link to="/feesandcharges" style={{color:'#003C69',textDecoration:'none'}}>FEES AND CHARGES</Link><br></br>
              <Link to="/investor" style={{color:'#003C69',textDecoration:'none'}}>INVESTOR</Link><br></br>
              <Link to="/termsandcondition" style={{color:'#003C69',textDecoration:'none'}}>TERMS AND CONDITION</Link><br></br>
              <Link to="/contactus" style={{color:'#003C69',textDecoration:'none'}}>CONTACT US</Link>
        </div>
      )}
      </div>
      <div className="mobileview" style={{backgroundColor:'#EBECEC'}}> 
        <div className="row" style={{margin:0,padding:0}}>
          <div className="col-3" style={{padding:5,marginLeft:10}}>
          <Link to="/"><img alt="logo" src={logo} style={{width:'100%'}}/></Link>
          </div>
          <div className="col-9" style={{marginLeft:-10}}>
            <div className="row">
              <div className="col-2"></div>
              <div className="col-2" style={{margin:0,padding:0,marginTop:5,marginLeft:10,}}>
                {user?.profilepic?.url && (
                  <img src={user.profilepic.url} style={{ width: '100%', borderRadius: 30 }} alt="Profile Pic" />
                )}
              </div>
              <div className="col-6" style={{fontSize:8,lineHeight:1.2,marginTop:10}}>
              {user ? (
                  <>
                    Welcome<br></br>
                    <b>{fullName}</b> - &nbsp;
                    {user.role}<br></br>
                    {user.town && user.town.name ? (
                      <>
                        {user.town.name},&nbsp;&nbsp;{user.state.name}<br />
                      </>
                    ) : null}<br></br>
                    <text style={{ fontSize: 10, color: '#003C69' }}> {date.toLocaleDateString()} &nbsp; {date.toLocaleTimeString()}</text><br />
                  </>
                ) : null}
              <text style={{fontSize:8,color:'#003C69'}}>{date.toLocaleDateString()} &nbsp; {date.toLocaleTimeString()}</text><br/>
              </div>
              <div className="col-2" style={{margin:0,padding:5,marginLeft:-20}}>
              <HoverImage style={{width:'100%'}} src={Menu} hoverSrc={MenuHover} onClick={handleMobileMenuClick}/>
              </div>
            </div>
            <div className="row" style={{margin:0,padding:0}}>
            <div className="col-2"></div>
              <div className="col-2" style={{margin:0,padding:5}}>
              <Link to="/search" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Search} hoverSrc={SearchHover}/></Link>
              </div>
              <div className="col-2"  style={{margin:0,padding:5}}>
              <Link to="/contact" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Contact} hoverSrc={ContactHover}/></Link>
              </div>
              <div className="col-2"  style={{margin:0,padding:5}}>
              <Link to="/blog" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Blog} hoverSrc={BlogHover}/></Link>
              </div>
              <div className="col-2" style={{margin:0,padding:5}}>
              <Link to="/message" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Message} hoverSrc={MessageHover}/></Link>
              </div>
              <div className="col-2" style={{margin:0,padding:5}}>
              <Link to="/notification" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={Notification} hoverSrc={NotificationHover}/></Link>
              </div>
            </div>
          </div>
        </div>
        {showMobileMenu && (
        <div className="menu-items" style={{position:'fixed',top:40,right:0,textAlign:'right',padding:20,backgroundColor:'#D9DADA'}}>
              <button style={{marginRight:100,textDecoration:'none',border:'none',backgroundColor:'none'}}onClick={handleMobileMenuClick}>x</button>
              <Link to="/aboutus" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>ABOUT US</Link><br></br>
              <Link to="/news" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>NEWS</Link><br></br>
              <Link to="/howitworks" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>HOW IT WORKS</Link><br></br>
              <Link to="/registeruser" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>HIRE A PROFESSIONAL</Link><br></br>
              <Link to="/earnwithus" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>EARN WITH US</Link><br></br>
              <Link to="/affiliatepolicy" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>AFFILIATE POLICY</Link><br></br>
              <Link to="/codeofconduct" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>CODE OF CONDUCT</Link><br></br>
              <Link to="/copyrightpolicy" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>COPYRIGHT POLICY</Link><br></br>
              <Link to="/privacypolicy" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>PRIVACY POLICY</Link><br></br>
              <Link to="/disclaimer" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>DISCLAIMER</Link><br></br>
              <Link to="/feesandcharges" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>FEES AND CHARGES</Link><br></br>
              <Link to="/investor" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>INVESTOR</Link><br></br>
              <Link to="/termsandcondition" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>TERMS AND CONDITION</Link><br></br>
              <Link to="/contactus" style={{color:'#003C69',textDecoration:'none',lineHeight:2}}>CONTACT US</Link>
        </div>
      )}
      </div>
    </div>
  )
}

export default HeaderAuth