import React from "react";

const truncateString = (str, maxChars) => {
  if (str.length > maxChars) {
    return str.slice(0, maxChars) + "...";
  }
  return str;
};

const MyJobContainer = ({ _id, name, description, town, state, industry, category, image, price, owner}) => {
  const maxDescriptionLength = 28; // Maximum character limit for description
  const maxIndustryLength = 15; // Maximum character limit for industry
  const maxCategoryLength = 15; // Maximum character limit for category
  
  const truncatedCategory = category.length > 15 ? `${category.substring(0, 15)}...` : category;
  const truncatedDescription = description.length > 40 ? `${description.substring(0, 40)}...` : description;
  const truncatedIndustry = industry.length > 17 ? `${industry.substring(0,17)}...` : industry;

  return (
    <div className="col-12" style={{margin:0,padding:0,}}>
    <div className="row" style={{margin:0,padding:5}}>
    <div className="col-12" style={{margin:0, padding:3,borderRadius:'20px',border: '1px solid #969696',backgroundColor:"#EFEEEE"}}>
      <div className="row" style={{margin:0,padding:0}}>
        <div className="col-5"style={{padding:0,borderRadius:'20px'}}>
          <img src={image} style={{width:'100%',borderRadius:'20px'}}></img>
        </div>
        <div className="col-7"style={{marginLeft:0,padding:5}}>
          <div className="row">
            <h6 className="job-title" style={{lineHeight:1, color:"#004274"}}>{name}</h6>
            <p style={{fontSize:11,lineHeight:1.3}}>
            <b>Description : </b>{truncateString('sam roy adhikari is the try unlimited power of the galaxy where this world belongs to him. He is the power infinite and unltimate true sole', maxDescriptionLength)}<br /><br></br>
            </p>
          </div>
          <div className="row">
            <p style={{fontSize:11,lineHeight:1.3, marginBottom:0}}>
            <b>Amount :</b> ₹ {price}<br></br>
            <b>Location : </b>{town}, {state}<br></br>
            <b>category : </b>{truncatedCategory}<br></br>
            <b>industry : </b>{truncatedIndustry}<br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default MyJobContainer;