import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { loadUser, getMyAds } from "../Actions/User";
import AdsContainer from "../componentsmall/MyAdsContainer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MyAds = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const settings1 = {
        dots: false,
        infinite: true,
        slidesToShow : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed : 1000,
        cssEase: "linear"
      };

const { user, loading: userLoading } = useSelector((state) => state.user);
const { loading, error, ads, activeAdsCount,inactiveAdsCount } = useSelector((state) => state.myAds);
const {
    error: likeError,
    message,
    loading: deleteLoading,
  } = useSelector((state) => state.likeAds);

  const dispatch = useDispatch();
const alert = useAlert();
 
let activeAds = [];
  let inactiveAds = [];

  if (ads) {
    activeAds = ads.filter(ads => ads.active === 1);
    inactiveAds = ads.filter(ads => ads.active === 0);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
useEffect(() => {
    dispatch(getMyAds());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="ads" style={{margin:0,padding:0}}>

        <div className="row" style={{margin:0,padding:0}}>
        {ads && ads.length > 0 ? (
          <div className="col-12" style={{margin:0}}>
            <p>You have {activeAdsCount} active ads</p>
          </div>
        ) : null}
        </div>

        <div className="row" style={{margin:0,padding:0}}>
        {activeAds && activeAds.length >= 3 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 3 : 1}>
            {activeAds && activeAds.length > 0 ? (
            activeAds.map((ads) => (
            <AdsContainer
                key={ads._id}
                id={ads._id}
                name={ads.name}
                description={ads.description}
                town={ads.town ? ads.town.name : ''}
                state={ads.state ? ads.state.name : ''}
                industry={ads.industry ? ads.industry.name : ''}
                category={ads.category ? ads.category.name : ''}
                image={ads.image.url}

                price={ads.price}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            activeAds.map((ads) => (
                <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
                <div className="row" style={{margin:0,padding:0}} >
              <AdsContainer 
                key={ads._id}
                id={ads._id}
                name={ads.name}
                description={ads.description}
                town={ads.town ? ads.town.name : ''}
                state={ads.state ? ads.state.name : ''}
                industry={ads.industry ? ads.industry.name : ''}
                category={ads.category ? ads.category.name : ''}
                image={ads.image.url}
                price={ads.price}
              />
              </div>
              </div>
            ))
          )}
        </div>


        <div className="row" style={{margin:0,padding:0,marginTop:30}}>
        {ads && ads.length > 0 ? (
            <div className="col-12">
                <p style={{ lineHeight: 1 }}>You have {inactiveAdsCount} inactive ads</p>
            </div>
        ) : null}
        </div>

        
        <div className="row">
        {inactiveAds && inactiveAds.length >= 3 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 3 : 1}>
            {inactiveAds && inactiveAds.length > 0 ? (
            inactiveAds.map((ads) => (
            <AdsContainer
                key={ads._id}
                id={ads._id}
                name={ads.name}
                description={ads.description}
                town={ads.town ? ads.town.name : ''}
                state={ads.state ? ads.state.name : ''}
                industry={ads.industry ? ads.industry.name : ''}
                category={ads.category ? ads.category.name : ''}
                image={ads.image.url}
                price={ads.price}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            inactiveAds.map((ads) => (
                <div className="col-lg-4">
                <div className="row">
              <AdsContainer 
                key={ads._id}
                id={ads._id}
                name={ads.name}
                description={ads.description}
                town={ads.town ? ads.town.name : ''}
                state={ads.state ? ads.state.name : ''}
                industry={ads.industry ? ads.industry.name : ''}
                category={ads.category ? ads.category.name : ''}
                image={ads.image.url}
                price={ads.price}
              />
              </div>
              </div>
            ))
          )}
        </div>

    </div>

  )
}

export default MyAds