import React, { useEffect }  from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { loadUser } from "../Actions/User";


import JobSameState from '../component/JobSameState'
import JobSameIndustry from '../component/JobSameIndustry'
import AdsSameIndustry from '../component/AdsSameIndustry'
import PremiumSameIndustry from '../component/PremiumSameIndustry'
import ProSameIndustry from '../component/ProSameIndustry'

const ProHome = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);
  

  useEffect(() => {
    if (!user.town || !user.state || !user.prodata.industry || !user.prodata.category1 || user.paymentpro !== 'yes') {
      navigate('/setting');
    }
  }, [user.town, user.state, user.prodata.industry, user.prodata.category1 || user.paymentpro !== 'yes']);
  
  return (
    <div className="row" style={{margin:0,padding:10}}>
      <div className="col-lg-12" style={{margin:0}}>
        <PremiumSameIndustry/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <AdsSameIndustry/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <JobSameIndustry/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <JobSameState/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <ProSameIndustry/>
      </div>
    </div>
  )
}

export default ProHome