import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { loadUser, getMyJob } from "../Actions/User";
import JobContainer from "../componentsmall/MyJobContainer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Myjob = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const settings1 = {
        dots: false,
        infinite: true,
        slidesToShow : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed : 1000,
        cssEase: "linear"
      };

const { user, loading: userLoading } = useSelector((state) => state.user);
const { loading, error, myjobs, activeJobCount,inactiveJobCount } = useSelector((state) => state.myJob);
const {
    error: likeError,
    message,
    loading: deleteLoading,
  } = useSelector((state) => state.likeJob);

  const dispatch = useDispatch();
const alert = useAlert();
 
let activeJobs = [];
  let inactiveJobs = [];

  if (myjobs) {
    activeJobs = myjobs.filter(job => job.active === 1);
    inactiveJobs = myjobs.filter(job => job.active === 0);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    dispatch(getMyJob(user._id)); 
}, [dispatch, user._id]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="jobs" style={{margin:0,padding:0}}>

        <div className="row" style={{margin:0,padding:0}}>
        {myjobs && myjobs.length > 0 ? (
          <div className="col-12" style={{margin:0}}>
            <p>You have {activeJobCount} active Jobs</p>
          </div>
        ) : null}
        </div>

        <div className="row" style={{margin:0,padding:0}}>
        {activeJobs && activeJobs.length >= 3 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 3 : 1}>
            {activeJobs && activeJobs.length > 0 ? (
            activeJobs.map((job) => (
            <JobContainer
                key={job._id}
                id={job._id}
                name={job.name}
                description={job.description}
                statetown={`${job.town ? job.town.name: ''}, ${job.state ? job.state.name: ''}`}
                industry={job.industry ? job.industry.name : ''}
                category={job.category ? job.category.name : ''}
                image={job.image.url}

                price={job.price}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            activeJobs.map((job) => (
                <div className="col-lg-4 sm-12" style={{margin:0,padding:0}}>
                <div className="row" style={{margin:0,padding:0}} >
              <JobContainer 
                key={job._id}
                id={job._id}
                name={job.name}
                description={job.description}
                statetown={`${job.town ? job.town.name: ''}, ${job.state ? job.state.name: ''}`}
                industry={job.industry ? job.industry.name : ''}
                category={job.category ? job.category.name : ''}
                image={job.image.url}
                price={job.price}
              />
              </div>
              </div>
            ))
          )}
        </div>


        <div className="row" style={{margin:0,padding:0,marginTop:30}}>
        {myjobs && myjobs.length > 0 ? (
            <div className="col-12">
                <p style={{ lineHeight: 1 }}>You have {inactiveJobCount} inactive Jobs</p>
            </div>
        ) : null}
        </div>

        
        <div className="row">
        {inactiveJobs && inactiveJobs.length >= 3 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 3 : 1}>
            {inactiveJobs && inactiveJobs.length > 0 ? (
            inactiveJobs.map((job) => (
            <JobContainer
                key={job._id}
                id={job._id}
                name={job.name}
                description={job.description}
                statetown={`${job.town ? job.town.name: ''}, ${job.state ? job.state.name: ''}`}
                industry={job.industry ? job.industry.name : ''}
                category={job.category ? job.category.name : ''}
                image={job.image.url}
                price={job.price}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            inactiveJobs.map((job) => (
                <div className="col-lg-4">
                <div className="row">
              <JobContainer 
                key={job._id}
                id={job._id}
                name={job.name}
                description={job.description}
                statetown={`${job.town ? job.town.name: ''}, ${job.state ? job.state.name: ''}`}
                industry={job.industry ? job.industry.name : ''}
                category={job.category ? job.category.name : ''}
                image={job.image.url}
                price={job.price}
              />
              </div>
              </div>
            ))
          )}
        </div>

    </div>

  )
}

export default Myjob