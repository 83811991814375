import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { createNewSupport } from "../../Actions/Support";
import { loadUser } from "../../Actions/User";
import addjob from "../../image/uploadimage.png";

const CreateSupport = () => {
  const [mobile, setMobile] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");

  const [states, setStates] = useState([]);
  const [state, setState] = useState("");

  const [towns, setTowns] = useState([]);
  const [town, setTown] = useState("");

  const [support, setSupport] = useState([]);

  const stateOptions = states.map(state => ({
    value: state._id,
    label: state.name
  }));

  const townOptions = towns.map(town => ({
    value: town._id,
    label: town.name
  }));

  useEffect(() => {
    // Fetch all "about us" entries when the component mounts
    const fetchSupport = async () => {
      try {
        const response = await axios.get('/api/v1/user/getallsupport');
        setSupport(response.data.data);
      } catch (error) {
        console.error(error);
        // Handle error, show error message, etc.
      }
    };

    fetchSupport();
  }, []);

  const { loading, error, message } = useSelector((state) => state.likeJob);
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    // Fetch states from the server
    axios.get('/api/v1/state')
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.error('Error fetching states:', error);
      });
  },[]);
  
  useEffect(() => {
    // Fetch towns based on the selected state
    if (state) {
      axios.get(`/api/v1/town/state/${state}`)
        .then(response => {
          setTowns(response.data);
        })
        .catch(error => {
          console.error('Error fetching towns:', error);
        });
    }
  }, [state])

  const handleDelete = async (entryId) => {
    // Send a delete request to the backend
    try {
      await axios.delete(`/api/v1/user/${entryId}`);
      // Remove the deleted entry from the local state
      setSupport(support.filter((entry) => entry._id !== entryId));
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
  
    // Check if password and confirmPassword match
    if (password !== confirmPassword) {
      alert.error("Password and Confirm Password must match.");
      return;
    }
  
    // Other form submission logic
    await dispatch(createNewSupport(state, town, mobile, email, password));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="row">
    <div className="col-lg-9">
    <h4>Support</h4>
            <tr style={{backgroundColor:'black',color:'white'}}>
              <th>Mobile</th>
              <th>Email</th>
              <th>State</th>
              <th>Town</th>
              <th>Action</th>
            </tr>
            {support.map((entry) => (
              <tr key={entry._id} style={{padding:'5px'}}>
                <td style={{padding:'5px',width:'20%'}}>{entry.mobile}</td>
                <td style={{padding:'5px',width:'30%'}}>{entry.email}</td>
                <td style={{width:'20%'}}>{entry.state.name}</td>
                <td style={{width:'20%'}}>{entry.town.name}</td>
                <td style={{width:'10%'}}>
                  <button style={{padding:'5px',border:0,backgroundColor:"red",color:"white"}} onClick={() => handleDelete(entry._id)}>Delete</button>
                </td>
              </tr>
            ))}
    </div>
    <div className="col-lg-3" >
      <form className="newPostForm" onSubmit={submitHandler} style={{height:480,overflowY:'scroll',paddingRight:15}}>
        <h3 className="h3">Create New Support</h3>

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div><br />

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div><br />

        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div><br />

        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div><br />
        <div className="form-group">
              <label htmlFor="state">State</label>
              <Select
                options={stateOptions}
                value={stateOptions.find(option => option.value === state)}
                onChange={selectedOption => setState(selectedOption.value)}
              />
        </div><br></br>
        <div className="form-group">
            <label htmlFor="town">Town</label>
              <Select
                options={townOptions}
                value={townOptions.find(option => option.value === town)}
                onChange={selectedOption => setTown(selectedOption.value)}
              />
        </div><br></br>
        <button className="btn btn-primary" type="submit">
          Create Support
        </button>
      </form>
    </div>
    </div>
  )
}

export default CreateSupport