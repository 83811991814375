import React, { useState } from 'react';

import AdminState from '../component/Admin/AdminState';
import AdminTown from '../component/Admin/AdminTown';
import AdminIndustry from '../component/Admin/AdminIndustry';
import AdminCategory from '../component/Admin/AdminCategory';
import AdminInvestor from '../component/Admin/AdminInvestor';
import AdminAboutus from '../component/Admin/AdminAboutus';
import AdminAffiliatepolicy from '../component/Admin/AdminAffiliatepolicy';
import AdminCodeofconduct from '../component/Admin/AdminCodeofconduct';
import AdminCopyrightpolicy from '../component/Admin/AdminCopyrightpolicy';
import AdminDisclaimer from '../component/Admin/AdminDisclaimer';
import AdminFeesandcharges from '../component/Admin/AdminFeesandcharges';
import AdminPrivacypolicy from '../component/Admin/AdminPrivacypolicy';
import AdminSupportduties from '../component/Admin/AdminSupportduties';
import AdminSupportearning from '../component/Admin/AdminSupportearning';
import AdminTermsandcondition from '../component/Admin/AdminTermsandcondition';
import AdminHomeCounts from '../component/Admin/AdminHomeCounts';
import AdminInactiveUser from '../component/Admin/AdminInactiveUser';
import AdminActiveUser from '../component/Admin/AdminActiveUser';
import AdminInactivePro from '../component/Admin/AdminInactivePro';
import AdminActivePro from '../component/Admin/AdminActivePro';
import AdminInactiveAds from '../component/Admin/AdminInactiveAds';
import AdminActiveAds from '../component/Admin/AdminActiveAds';
import AdminInactiveJob from '../component/Admin/AdminInactiveJob';
import AdminActiveJob from '../component/Admin/AdminActiveJob';
import CreateSupport from '../component/Admin/CreateSupport';
import AdminSupport from '../component/Admin/AdminSupport';
import AdminTopup from '../component/Admin/AdminTopup';
import AdminPremium from '../component/Admin/AdminPremium';
import AdminInactiveNews from '../component/Admin/AdminInactiveNews';
import AdminActiveNews from '../component/Admin/AdminActiveNews';
import AdminCompletedOrder from '../component/Admin/AdminCompletedOrder';
import AdminIncompleteOrder from '../component/Admin/AdminIncompleteOrder';
import AdminWithdrawalRequest from '../component/Admin/AdminWithdrawalRequest';
import AdminActiveBlog from '../component/Admin/AdminActiveBlog';
import AdminInactiveBlog from '../component/Admin/AdminInactiveBlog';
import CreateAdminBlog from '../component/Admin/CreateAdminBlog';
import CreateAdminContest from '../component/Admin/CreateAdminContest';
import AdminFlexslider from '../component/Admin/AdminFlexslider';

const AdminHome = () => {
  const [menuState, setMenuState] = useState({
    homediv:true,
    users: false,
    inactiveuser: false,
    activeuser:false,
    pro: false,
    inactivepro: false,
    activepro: false,
    ads: false,
    inactiveads: false,
    activeads:false,
    job: false,
    activejob: false,
    inactivejob : false,
    support: false,
    createsupport: false,
    allsupport: false,
    topup:false,
    news: false,
    activenews: false,
    inactivenews: false,
    order: false,
    completedorder: false,
    incompleteorder: false,
    withdrawalrequest:false,
    blog: false,
    inactiveblog: false,
    activeblog: false,
    createadminblog: false,
    createadmincontest: false,
    webcategories: false,
    aboutus: false,
    affiliatepolicy: false,
    codeofconduct: false,
    copyrightpolicy: false,
    disclaimer: false,
    feesandcharges: false,
    investor : false,
    privacypolicy: false,
    termsandcondition: false,
    supportduties: false,
    supportearning: false,
    state: false,
    town : false,
    industry: false,
    category: false,
    premium: false,
    flexslider: false,
  });

  const toggleSubMenu = (menuItem) => {
    // Create a new object with all sections hidden except the clicked one
    const newMenuState = {};
    Object.keys(menuState).forEach((key) => {
      newMenuState[key] = key === menuItem;
    });
    setMenuState(newMenuState);
  };

  return (
    <div className="row" style={{padding:10,}}>
      <div className="col-2" style={{padding:15, backgroundColor:'#D9E2DB', height: 520, overflowY: 'hidden',marginLeft:10,borderRadius:5,}}>
        <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('homediv')}>
          Home
        </div>
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('users')}>
          Users
        </div>
        {menuState.users && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer' }}>

          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('activeuser')}>
          Active Users<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('inactiveuser')}>
          Inactive Users
          </div>

        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('pro')}>
          Pro
        </div>
        {menuState.pro && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('activepro')}>
          Active Professional<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('inactivepro')}>
          Inactive Professional
          </div>
        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('ads')}>
          Ads
        </div>
        {menuState.ads && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('activeads')}>
          Active Ads<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('inactiveads')}>
          Inactive Ads
          </div>
        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('job')}>
          Job
        </div>
        {menuState.job && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('activejob')}>
          Active Job<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('inactivejob')}>
          Inactive Job
          </div>
        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('support')}>
          Support
        </div>
        {menuState.support && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('createsupport')}>
          Create Support<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('allsupport')}>
          View all Support<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('topup')}>
          Topup
          </div>
        </div>
        )}
        <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('premium')}>
          Premium
        </div>
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('news')}>
          News
        </div>
        {menuState.news && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('activenews')}>
          Active News<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('inactivenews')}>
          Inactive News
          </div>
        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('order')}>
          Order
        </div>
        {menuState.order && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('completedorder')}>
          Completed Orders<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('incompleteorder')}>
          Incomplete Orders<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('withdrawalrequest')}>
          Withdrawal Request
          </div>
        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('blog')}>
          Blog
        </div>
        {menuState.blog && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('activeblog')}>
          Active Blogs<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('inactiveblog')}>
          Inactive Blogs<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('createadminblog')}>
          Create Admin Blog<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('createadmincontest')}>
          Admin Contests
          </div>
        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}} onClick={() => toggleSubMenu('webcategories')}>
          WebCategories
        </div>
        {menuState.webcategories && (
        <div style={{marginLeft:20,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('aboutus')}>
          About Us<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('affiliatepolicy')}>
          Affiliate Policy<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('codeofconduct')}>
          Code of Conduct<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('copyrightpolicy')}>
          Copyright Policy<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('disclaimer')}>
          Disclaimer<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('feesandcharges')}>
          Fees and Charges<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('investor')}>
          Investor<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('privacypolicy')}>
          Privacy Policy<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('termsandcondition')}>
          Terms and Condition<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('supportduties')}>
          Support Duties<br/>
          </div>
          <div style={{marginLeft:10, color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('supportearning')}>
          Support Earnings
          </div>
        </div>
        )}
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('industry')}>
          Industry
        </div>
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('category')}>
          Category
        </div>
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('state')}>
          State
        </div>
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('town')}>
         Town
        </div>
        <div style={{marginLeft:10,color:'black',textDecoration:'none',fontSize:13,cursor: 'pointer'}}  onClick={() => toggleSubMenu('flexslider')}>
         Flexslider
        </div>
      </div>


      <div className="col-10" style={{marginLeft:-10,padding:10}}>
      {menuState.homediv && (
      <div className="" style={{margin:0,padding:0}}>
        <AdminHomeCounts/>
      </div>
      )}

      {menuState.inactiveuser && (
      <div className="" style={{}}>
        <AdminInactiveUser/>
      </div>
      )}
      
      {menuState.activeuser && (
      <div className="" style={{}}>
        <AdminActiveUser/>
      </div>
      )}
      
      {menuState.inactivepro && (
      <div className="" style={{}}>
        <AdminInactivePro/>
      </div>
      )}
      
      {menuState.activepro && (
      <div className="" style={{}}>
        <AdminActivePro/>
      </div>
      )}
      
      {menuState.inactiveads && (
      <div className="" style={{}}>
        <AdminInactiveAds/>
      </div>
      )}
      
      {menuState.activeads && (
      <div className="" style={{}}>
       <AdminActiveAds/>
      </div>
      )}
      
      {menuState.inactivejob && (
      <div className="" style={{}}>
        <AdminInactiveJob/>
      </div>
      )}
      
      {menuState.activejob && (
      <div className="" style={{}}>
        <AdminActiveJob/>
      </div>
      )}
      
      {menuState.createsupport && (
      <div className="" style={{}}>
        <CreateSupport/>
      </div>
      )}
      
      {menuState.allsupport && (
      <div className="" style={{}}>
        <AdminSupport/>
      </div>
      )}
      
      {menuState.topup && (
      <div className="" style={{}}>
        <AdminTopup/>
      </div>
      )}
      
      {menuState.premium && (
      <div className="" style={{}}>
        <AdminPremium/>
      </div>
      )}

      {menuState.inactivenews && (
      <div className="" style={{}}>
        <AdminInactiveNews/>
      </div>
      )}
      
      {menuState.activenews && (
      <div className="" style={{}}>
        <AdminActiveNews/>
      </div>
      )}

      {menuState.completedorder && (
      <div className="" style={{}}>
        <AdminCompletedOrder/>
      </div>
      )}
      
      {menuState.incompleteorder && (
      <div className="" style={{}}>
       <AdminIncompleteOrder/>
      </div>
      )}
      
      {menuState.withdrawalrequest && (
      <div className="" style={{}}>
        <AdminWithdrawalRequest/>
      </div>
      )}

      {menuState.activeblog && (
      <div className="" style={{}}>
        <AdminActiveBlog/>
      </div>
      )}
      
      {menuState.inactiveblog && (
      <div className="" style={{}}>
       <AdminInactiveBlog/>
      </div>
      )}
      
      {menuState.createadminblog && (
      <div className="" style={{}}>
        <CreateAdminBlog/>
      </div>
      )}

      {menuState.createadmincontest && (
      <div className="" style={{}}>
       <CreateAdminContest/>
      </div>
      )}

      {menuState.aboutus && (
      <div className="" style={{}}>
        <AdminAboutus/>
      </div>
      )}
      
      {menuState.affiliatepolicy && (
      <div className="" style={{}}>
        <AdminAffiliatepolicy/>
      </div>
      )}
      
      {menuState.codeofconduct && (
      <div className="" style={{}}>
        <AdminCodeofconduct/>
      </div>
      )}
      
      {menuState.copyrightpolicy && (
      <div className="" style={{}}>
        <AdminCopyrightpolicy/>
      </div>
      )}
      
      {menuState.disclaimer && (
      <div className="" style={{}}>
       <AdminDisclaimer/>
      </div>
      )}
      
      {menuState.feesandcharges && (
      <div className="" style={{}}>
        <AdminFeesandcharges/>
      </div>
      )}
      
      {menuState.investor && (
      <div className="" style={{}}>
        <AdminInvestor/>
      </div>
      )}
      
      {menuState.privacypolicy && (
      <div className="" style={{}}>
        <AdminPrivacypolicy/>
      </div>
      )}
      
      {menuState.termsandcondition && (
      <div className="" style={{}}>
        <AdminTermsandcondition/>
      </div>
      )}
      
      {menuState.supportduties && (
      <div className="" style={{}}>
        <AdminSupportduties/>
      </div>
      )}
      
      {menuState.supportearning && (
      <div className="" style={{}}>
        <AdminSupportearning/>
      </div>
      )}

      {menuState.industry && (
      <div className="" style={{}}>
        <AdminIndustry/>
      </div>
      )}
      
      {menuState.category && (
      <div className="" style={{}}>
        <AdminCategory/>
      </div>
      )}
      
      {menuState.state && (
      <div className="" style={{}}>
        <AdminState/>
      </div>
      )}
      
      {menuState.town && (
      <div className="" style={{}}>
       <AdminTown/>
      </div>
      )}

      {menuState.flexslider && (
        <div className="" style={{}}>
         <AdminFlexslider/>
        </div>
        )}
      </div>
      
      
      
    </div>
  )
}

export default AdminHome