import React from "react";


const PremiumContainer = ({ _id, image}) => {

  return (
    <div className="col-12" style={{margin:0,padding:0,}}>
      <div className="row" style={{margin:0,paddingLeft:5,paddingRight:5}}>
        <div className="col-12" style={{margin:0, paddingLeft:3,paddingRight:5,borderRadius:'20px'}}>
          <div className="row" style={{margin:0,padding:0}}>
            <img src={image} style={{width:'100%',}}></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumContainer;