import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CreateFeesandcharges from "./CreateFeesandcharges"
import UpdateFeesandcharges from "./UpdateFeesandcharges"

const AdminFeesandcharges = () => {
  const [feesandchargesEntries, setfeesandchargesEntries] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);

  useEffect(() => {
    // Fetch all "about us" entries when the component mounts
    const fetchfeesandchargesEntries = async () => {
      try {
        const response = await axios.get('/api/v1/webcategory/viewfeesandcharges/');
        setfeesandchargesEntries(response.data.data);
      } catch (error) {
        console.error(error);
        // Handle error, show error message, etc.
      }
    };

    fetchfeesandchargesEntries();
  }, []);

  const handleUpdate = (entryId) => {
    setSelectedEntryId(entryId);
  };


  const handleDelete = async (entryId) => {
    // Send a delete request to the backend
    try {
      await axios.delete(`/api/v1/webcategory/deletefeesandcharges/${entryId}`);
      // Remove the deleted entry from the local state
      setfeesandchargesEntries(feesandchargesEntries.filter((entry) => entry._id !== entryId));
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <div className="row">
      
      <div className="col-9"style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
        <h4>fees and charges Entries</h4>
            <tr style={{backgroundColor:'black',color:'white'}}>
              <th>Heading</th>
              <th>Description</th>
              <th>Action</th>
              <th>Action</th>
            </tr>
            {feesandchargesEntries.map((entry) => (
              <tr key={entry._id} style={{padding:'5px'}}>
                <td style={{padding:'5px'}}>{entry.heading}</td>
                <td style={{padding:'5px'}}>{entry.description}</td>
                <td>
                  <button style={{padding:'5px',border:0,backgroundColor:"red",color:"white"}} onClick={() => handleUpdate(entry._id)}>Update</button>
                </td>
                <td>
                  <button style={{padding:'5px',border:0,backgroundColor:"red",color:"white"}} onClick={() => handleDelete(entry._id)}>Delete</button>
                </td>
              </tr>
            ))}
      </div>
      <div className="col-3" style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
      {selectedEntryId ? (
          <UpdateFeesandcharges entryId={selectedEntryId}  />
        ) : (
          <CreateFeesandcharges />
        )}
      </div>
    </div>
  );
};

export default AdminFeesandcharges;