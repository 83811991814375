import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { loadUser} from "../Actions/User";
import {getAllPremium} from "../Actions/Premium"
import PremiumContainer from "../componentsmall/PremiumContainer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PremiumAll = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const settings1 = {
        dots: false,
        infinite: true,
        slidesToShow : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed : 30000,
        cssEase: "linear"
      };

const { user, loading: userLoading } = useSelector((state) => state.user);
const { loading, error, premium } = useSelector((state) => state.PremiumCountry);
const {
    error: likeError,
    message,
    loading: deleteLoading,
  } = useSelector((state) => state.Job);
  

  const dispatch = useDispatch();
const alert = useAlert();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    dispatch(getAllPremium()); // Invoke the action function
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="premiums" style={{margin:0,padding:0}}>
        <div className="row" style={{margin:0,padding:0}}>
        {premium && premium.length >= 4 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 4 : 1}>
            {premium && premium.length > 0 ? (
            premium.map((premium) => (
            <PremiumContainer
                key={premium._id}
                id={premium._id}
                image={premium.image.url}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            premium.map((premium) => (
                <div className="col-lg-3 sm-12" style={{margin:0,padding:0}}>
                <div className="row" style={{margin:0,padding:0}} >
              <PremiumContainer 
                key={premium._id}
                id={premium._id}
                image={premium.image.url}
              />
              </div>
              </div>
            ))
          )}
        </div>

    </div>

  )
}

export default PremiumAll