import { createReducer } from "@reduxjs/toolkit";
import {  CLEAR_ERRORS, PRO_DETAILS_FAIL, PRO_DETAILS_REQUEST, PRO_DETAILS_RESET, 
  PRO_DETAILS_SUCCESS } from "../constants/userConstants";


const initialState = {
  loading: false,
  pro: [],
  error: null,
};

export const ProNewReducer = createReducer(initialState, {
  getProNewRequest: (state) => {
    state.loading = true;
  },
  getProNewSuccess: (state, action) => {
    state.loading = false;
    state.pro = action.payload.pro; // Update pro state
    // Update adCount state
  },
  getProNewFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});


