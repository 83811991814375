import axios from "axios";

export const likeJob = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "likeJobRequest",
    });

    const { data } = await axios.get(`/api/v1/job/${id}`);
    dispatch({
      type: "likeJobSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "likeJobFailure",
      payload: error.response.data.message,
    });
  }
};

export const addCommentOnJob = (id, comment) => async (dispatch) => {
  try {
    dispatch({
      type: "addCommentJobRequest",
    });

    const { data } = await axios.put(
      `/api/v1/job/comment/${id}`,
      {
        comment,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "addCommentJobSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "addCommentJobFailure",
      payload: error.response.data.message,
    });
  }
};

export const deleteCommentOnJob = (id, commentId) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteCommentJobRequest",
    });

    const { data } = await axios.delete(`/api/v1/job/comment/${id}`, {
      data: { commentId },
    });
    dispatch({
      type: "deleteCommentJobSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteCommentJobFailure",
      payload: error.response.data.message,
    });
  }
};

export const createNewJob = ( name, description, price, state, town, category, industry, image) => async (dispatch) => {
  try {
    dispatch({
      type: "newJobRequest",
    });

    const { data } = await axios.post(
      `/api/v1/job/`,
      {
        name, description, price, state, town, category, industry, image,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "newJobSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "newJobFailure",
      payload: error.response.data.message,
    });
  }
};

export const updateJob = (caption, id) => async (dispatch) => {
  try {
    dispatch({
      type: "updateJobRequest",
    });

    const { data } = await axios.put(
      `/api/v1/job/${id}`,
      {
        caption,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "updateJobSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateJobFailure",
      payload: error.response.data.message,
    });
  }
};

export const deleteJob = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteJobRequest",
    });

    const { data } = await axios.delete(`/api/v1/job/${id}`);
    dispatch({
      type: "deleteJobSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteJobFailure",
      payload: error.response.data.message,
    });
  }
};


export const getJobWithState = (stateId) => async (dispatch) => {
  try {
    dispatch({
      type: "getJobWithStateRequest",
    });

    const { data } = await axios.get(`/api/v1/job/state/${stateId}`);
    dispatch({
      type: "getJobWithStateSuccess",
      payload: {
        jobs: data.jobs,
        totalJobs : data.totalJobs,
      },
    });
  } catch (error) {
    dispatch({
      type: "getJobWithStateFailure",
      payload: error.response.data.message,
    });
  }
};

export const getJobNew = () => async (dispatch) => {
  try {
    dispatch({
      type: "getJobNewRequest",
    });

    const { data } = await axios.get(`/api/v1/job/getactivejobnew`);
    dispatch({
      type: "getJobNewSuccess",
      payload: {
        jobs: data.jobs,
      },
    });
  } catch (error) {
    dispatch({
      type: "getJobNewFailure",
      payload: error.response.data.message,
    });
  }
};


