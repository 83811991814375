import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadUser, updatePro3 } from '../../Actions/User';
import axios from 'axios'
import Select from 'react-select';

const ProSetting3 = ({ dispatch, setSubMenu }) => {
  const user = useSelector((state) => state.user.user);

const [aadhaar, setAadhaar] = useState(user.prodata.aadhaar);
const [aadhaaraadhaarimage, setAadhaarimage] = useState(user.prodata.aadhaaraadhaarimage);
const [pan, setPan] = useState(user.prodata.pan);
const [panaadhaarimage, setPanimage] = useState(user.prodata.panaadhaarimage);
const [bankname, setBankname] = useState(user.prodata.bankname);
const [accountnumber, setAccountnumber] = useState(user.prodata.accountnumber);
const [holdername, setHoldername] = useState(user.prodata.holdername);
const [ifsccode, setIfsccode] = useState(user.prodata.ifsccode);


  const handleSubmit= async (e) => {
    e.preventDefault();
    await dispatch(updatePro3(aadhaar,aadhaaraadhaarimage,pan,panaadhaarimage,bankname, accountnumber,holdername,ifsccode));
    dispatch(loadUser());
    setSubMenu('prosetting4');
  };

  const handleAadhaarImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Read the file as a data URL and set it in the formData
      const reader = new FileReader();
      reader.onload = (event) => {
        setAadhaarimage(event.target.result)
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePanImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Read the file as a data URL and set it in the formData
      const reader = new FileReader();
      reader.onload = (event) => {
        setPanimage(event.target.result) // Set the image as a base64-encoded string
      };
      reader.readAsDataURL(file);
    }
  };



  return (
    <form onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-12">
              <h2>Banking Information</h2>  
            </div>
        </div>
          <div className="form-group">
              <label htmlFor="aadhaar">Aadhaar Number</label>
              <input
                type="text"
                className="form-control"
                id="aadhaar"
                name="aadhaar"
                value={aadhaar}
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => setAadhaar(e.target.value)}
              />
          </div><br></br>

          <div className="form-group">
            <label htmlFor="aadhaarimage">Upload Aadhaar</label><br />
            <input
              type="file"
              id="aadhaarimage"
              name="aadhaarimage"
              accept="aadhaarimage/*"
              onChange={handleAadhaarImageChange}
            />
          </div><br></br>
           
          <div className="form-group">
              <label htmlFor="pan">PAN Number</label>
              <input
                type="text"
                className="form-control"
                id="pan"
                name="pan"
                value={pan}
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => setPan(e.target.value)}
              />
          </div><br></br>

          <div className="form-group">
            <label htmlFor="panimage">Upload PAN</label><br />
            <input
              type="file"
              id="panimage"
              name="panimage"
              accept="panimage/*"
              onChange={handlePanImageChange}
            />
          </div><br></br>
          
          <div className="form-group">
              <label htmlFor="bankname">Your Bank Name</label>
              <input
                type="text"
                className="form-control"
                id="bankname"
                name="bankname"
                value={bankname}
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => setBankname(e.target.value)}
              />
          </div><br></br>
          
          <div className="form-group">
              <label htmlFor="accountnumber">Account Number</label>
              <input
                type="text"
                className="form-control"
                id="accountnumber"
                name="accountnumber"
                value={accountnumber}
                onChange={(e) => setAccountnumber(e.target.value)}
              />
          </div><br></br>
    
          <div className="form-group">
              <label htmlFor="holdername">Holder's Name</label>
              <input
                type="text"
                className="form-control"
                id="holdername"
                name="holdername"
                value={holdername}
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => setHoldername(e.target.value)}
              />
            </div><br></br>

            <div className="form-group">
              <label htmlFor="ifsccode">Ifsc code</label>
              <input
                type="text"
                className="form-control"
                id="ifsccode"
                name="ifsccode"
                value={ifsccode}
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => setIfsccode(e.target.value)}
              />
            </div><br></br>
           
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
        </form>
  );
};

export default ProSetting3;
