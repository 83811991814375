import React from "react";
import { Link} from "react-router-dom";

const truncateString = (str, maxChars) => {
  if (str.length > maxChars) {
    return str.slice(0, maxChars) + "...";
  }
  return str;
};

const ProContainer = ({ _id, name, image, statetown, industry, category1, category2, category3, category4, category5}) => {
  const maxCategoryLength = 28; // Maximum character limit for categories
  const maxIndustryLength = 15; // Maximum character limit for industry

  return (
        <Link to={{ pathname: "/proprofile", search: `?id=${_id}` }} style={{ textDecoration: 'none', margin: 0, padding: 0 }}>
      <div className="col-12" style={{ margin: 0, padding: 0 }}>
        <div className="row" style={{ margin: 0, padding: 5 }}>
          <div className="col-12" style={{ borderRadius: '85px 10px 10px 85px', border: '1px solid #969696', marginLeft: 20, padding: 10 }}>
            <div className="row" style={{ margin: 0, padding: 0 }}>
              <div className="col-5" style={{ marginLeft: 0, padding: 0 }}>
                <img src={image} style={{ width: '100%', borderRadius: '60px' }} alt="Profile" />
              </div>
              <div className="col-7" style={{ marginLeft: 0, padding: 5 }}>
                <h6 className="job-title" style={{ lineHeight: 1 }}></h6>
                <p style={{ fontSize: 17, lineHeight: 1, marginTop: -10, marginBottom: 0, color: '#004274' }}>
                  {name}<br />
                  <b style={{ fontSize: 11, color: 'black' }}>
                    {statetown}
                  </b>
                </p>
                <p style={{ fontSize: 11, lineHeight: 1.3, marginBottom: 0, color: 'black' }}><br />
                  <b>INDUSTRY :</b> &nbsp;
                  {truncateString(industry, maxIndustryLength)}<br />
                  <b>CATEGORIES :</b><br />
                  {truncateString(category1, maxCategoryLength)}<br />
                  {truncateString(category2, maxCategoryLength)}<br />
                  {truncateString(category3, maxCategoryLength)}<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProContainer;