import React,{useEffect} from "react";
import Login from '../component/Login';
import ViewFlexslider from '../component/ViewFlexslider';
import ProNew from '../component/ProNew'
import JobNew from '../component/JobNew'
import AdsNew from '../component/AdsNew'
import PremiumAll from '../component/PremiumAll'

import { useDispatch } from "react-redux";

import { loadUser } from "../Actions/User";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
    
  }, [dispatch]);
  
  return (
    <div className="row">
      <div className="col-lg-9 sm-12" style={{margin:0,padding:0, }}>
        <ViewFlexslider/>
      </div>
      <div className="col-lg-3 sm-12" style={{margin:0,padding:20}}>
        <Login/>
      </div>
      <div className="col-12"style={{margin:0,paddingTop:10,paddingLeft:20,paddingRight:20}}>
        <PremiumAll/>
      </div>
      <div className="col-12"style={{margin:0,paddingTop:10,paddingLeft:20,paddingRight:20}}>
        <ProNew/>
      </div>
      <div className="col-12"style={{margin:0,paddingTop:10,paddingLeft:20,paddingRight:20}}>
        <JobNew/>
      </div>
      <div className="col-12"style={{margin:0,paddingTop:10,paddingLeft:20,paddingRight:20}}>
        <AdsNew/>
      </div>
    </div>
  )
}

export default Home