import axios from "axios";


export const createNewPremium = ( name, dateFrom, dateTo, state, town, category, industry, image) => async (dispatch) => {
  try {
    dispatch({
      type: "newPremiumRequest",
    });

    const { data } = await axios.post(
      `/api/v1/premium/support/`,
      {
        name, dateFrom, dateTo, state, town, category, industry, image,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "newPremiumSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "newPremiumFailure",
      payload: error.response.data.message,
    });
  }
};

export const updatePremium = (caption, id) => async (dispatch) => {
  try {
    dispatch({
      type: "updatePremiumRequest",
    });

    const { data } = await axios.put(
      `/api/v1/premium/${id}`,
      {
        caption,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "updatePremiumSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updatePremiumFailure",
      payload: error.response.data.message,
    });
  }
};

export const getAllPremium = () => async (dispatch) => {
    try {
      dispatch({
        type: "getPremiumRequest",
      });
  
      const { data } = await axios.get(`/api/v1/premium/`);
      dispatch({
        type: "getPremiumSuccess",
        payload: {
          premium: data.premium,
        },
      });
    } catch (error) {
      dispatch({
        type: "getPremiumFailure",
        payload: error.response.data.message,
      });
    }
  };
  

export const getPremiumWithState = (stateId) => async (dispatch) => {
  try {
    dispatch({
      type: "getPremiumWithStateRequest",
    });

    const { data } = await axios.get(`/api/v1/premium/state/${stateId}`);
    dispatch({
      type: "getPremiumWithStateSuccess",
      payload: {
        premium: data.premium,
        totalAds : data.totalAds,
      },
    });
  } catch (error) {
    dispatch({
      type: "getPremiumWithStateFailure",
      payload: error.response.data.message,
    });
  }
};

