import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const JobProfile = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("id");

  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    if (!jobId) {
      // Handle the case when jobId is not available
      return;
    }

    // Fetch job details based on the jobId
    // You can make an API request to your server here.
    // Replace the following fetch request with your actual API call.
    fetch(`/api/v1/job/${jobId}`) // Assuming you have an API endpoint for job details
      .then((response) => response.json())
      .then((data) => {
        setJobDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  }, [jobId]);

  if (!jobDetails) {
    // You can show a loading spinner here while data is being fetched.
    return <div>Loading...</div>;
  }

  return (
    <div className="job-profile">
      <h1>{jobDetails.name}</h1>
      <p>{jobDetails.description}</p>
      <p>Location: {jobDetails.town}, {jobDetails.state}</p>
      <p>Category: {jobDetails.category}</p>
      <p>Industry: {jobDetails.industry}</p>
      <p>Price: ₹{jobDetails.price}</p>
      {/* Add more job details as needed */}
    </div>
  );
};

export default JobProfile;