import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UpdatePremium from "./UpdatePremium";
import CreatePremium from "../Admin/CreatePremium"

const AdminPremium = () => {
  const [premiumEntries, setpremiumEntries] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);

  useEffect(() => {
    // Fetch all "about us" entries when the component mounts
    const fetchpremiumEntries = async () => {
      try {
        const response = await axios.get('/api/v1/premium/');
        setpremiumEntries(response.data.premium);
      } catch (error) {
        console.error(error);
        // Handle error, show error message, etc.
      }
    };

    fetchpremiumEntries();
  }, []);

  const handleUpdate = (entryId) => {
    setSelectedEntryId(entryId);
  };


  const handleDelete = async (entryId) => {
    // Send a delete request to the backend
    try {
      await axios.delete(`/api/v1/premium/${entryId}`);
      // Remove the deleted entry from the local state
      setpremiumEntries(premiumEntries.filter((entry) => entry._id !== entryId));
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <div className="row">
      
      <div className="col-7"style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
        <h4>Premium Entries</h4>
            <tr style={{backgroundColor:'black',color:'white'}}>
              <th>title</th>
              <th>Image</th>
              <th>Action</th>
              <th>Action</th>
            </tr>
            {premiumEntries.map((entry) => (
              <tr key={entry._id} style={{padding:'5px'}}>
                <td style={{padding:'5px',width:'30%'}}>{entry.name}</td>
                <td style={{padding:'5px',width:'50%'}}><img src={entry.image.url} style={{width:'100%',borderRadius:'20px'}}></img></td>
                <td style={{width:'10%'}}>
                  <button style={{padding:'5px',border:0,backgroundColor:"red",color:"white"}} onClick={() => handleUpdate(entry._id)}>Update</button>
                </td>
                <td style={{width:'10%'}}>
                  <button style={{padding:'5px',border:0,backgroundColor:"red",color:"white"}} onClick={() => handleDelete(entry._id)}>Delete</button>
                </td>
              </tr>
            ))}
      </div>
      <div className="col-5" style={{margin:0,padding:15,fontSize:'14px',height:'450px'}}>
      {selectedEntryId ? (
          <UpdatePremium entryId={selectedEntryId}  />
        ) : (
          <CreatePremium />
        )}
      </div>
    </div>
  );
};

export default AdminPremium;