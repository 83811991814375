import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { loadUser} from "../Actions/User";
import {getProNew } from "../Actions/Pro"
import ProContainer from "../componentsmall/ProContainer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProNew = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const settings1 = {
        dots: false,
        infinite: true,
        slidesToShow : 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed : 1000,
        cssEase: "linear"
      };

const { user, loading: userLoading } = useSelector((state) => state.user);
const { loading, error, pro } = useSelector((state) => state.ProNew);
const {
    error: likeError,
    message,
    loading: deleteLoading,
  } = useSelector((state) => state.Job);
  

  const dispatch = useDispatch();
const alert = useAlert();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    dispatch(getProNew()); // Invoke the action function
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="pros" style={{margin:0,padding:0}}>

        <div className="row" style={{margin:0,padding:0}}>
        {pro && pro.length > 0 ? (
          <div className="col-12" style={{margin:0}}>
            
            Professionals from various backgrounds
          </div>
        ) : null}
        </div>

        <div className="row" style={{margin:0,padding:0}}>
        {pro && pro.length >= 4 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 4 : 1}>
            {pro && pro.length > 0 ? (
            pro.map((pro) => (
            <ProContainer
                key={pro._id}
                id={pro._id}
                name={`${pro.firstname} ${pro.lastname}`}
                statetown={`${pro.town ? pro.town.name: ''}, ${pro.state ? pro.state.name: ''}`}
                image={pro.profilepic.url}
                industry={pro.prodata && pro.prodata.industry ? pro.prodata.industry.name : ''}
                category1={pro.prodata && pro.prodata.category1 ? pro.prodata.category1.name : ''}
                category2={pro.prodata && pro.prodata.category2 ? pro.prodata.category2.name : ''}
                category3={pro.prodata && pro.prodata.category3 ? pro.prodata.category3.name : ''}
                category4={pro.prodata && pro.prodata.category4 ? pro.prodata.category4.name : ''}
                category5={pro.prodata && pro.prodata.category5 ? pro.prodata.category5.name : ''}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            pro.map((pro) => (
                <div className="col-lg-3 sm-12" style={{margin:0,padding:0}}>
                <div className="row" style={{margin:0,padding:0}} >
              <ProContainer 
                key={pro._id}
                id={pro._id}
                name={`${pro.firstname} ${pro.lastname}`}
                statetown={`${pro.town ? pro.town.name: ''}, ${pro.state ? pro.state.name: ''}`}
                image={pro.profilepic.url}
                industry={pro.prodata && pro.prodata.industry ? pro.prodata.industry.name : ''}
                category1={pro.prodata && pro.prodata.category1 ? pro.prodata.category1.name : ''}
                category2={pro.prodata && pro.prodata.category2 ? pro.prodata.category2.name : ''}
                category3={pro.prodata && pro.prodata.category3 ? pro.prodata.category3.name : ''}
                category4={pro.prodata && pro.prodata.category4 ? pro.prodata.category4.name : ''}
                category5={pro.prodata && pro.prodata.category5 ? pro.prodata.category5.name : ''}
              />
              </div>
              </div>
            ))
          )}
        </div>

    </div>

  )
}

export default ProNew