import React, { useEffect }  from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import JobSameState from '../component/JobSameState'
import AdsSameState from '../component/AdsSameState'
import PremiumSameState from '../component/PremiumSameState'
import ProSameState from '../component/ProSameState'

const UserHome = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.town) {
      navigate('/setting');
    }
  }, [user.town, navigate]);

  return (
    <div className="row" style={{margin:0,padding:10}}>
      <div className="col-lg-12" style={{margin:0}}>
        <PremiumSameState/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <JobSameState/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <AdsSameState/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <ProSameState/>
      </div>
    </div>
  )
}

export default UserHome