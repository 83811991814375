import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { loadUser} from "../Actions/User";
import { getAdsWithState} from "../Actions/Ads";
import AdsContainer from "../componentsmall/AdsContainer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AdsSameState = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const settings1 = {
        dots: false,
        infinite: true,
        rtl: true,
        slidesToShow : 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 20000,
        autoplaySpeed : 1000,
        cssEase: "linear"
      };

const { user, loading: userLoading } = useSelector((state) => state.user);
const { loading, error, ads, totalAds } = useSelector((state) => state.Ad);

const stateId = user.state && user.state._id;

const {
    error: likeError,
    message,
    loading: deleteLoading,
  } = useSelector((state) => state.likeAds);

  const dispatch = useDispatch();
const alert = useAlert();
 

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    if (stateId) {
      dispatch(getAdsWithState(stateId));
    }
  }, [dispatch, stateId]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      alert.success(message);
      dispatch({ type: "clearMessage" });
    }
  }, [dispatch, error, message, alert]);

  return (
    <div className="ads" style={{margin:0,padding:0}}>

        <div className="row" style={{margin:0,padding:0}}>
        {ads && ads.length > 0 ? (
          <div className="col-12" style={{margin:0}}>
            <p>Other ads from {user.state.name} - ({totalAds} resuts)</p>
          </div>
        ) : null}
        </div>

        <div className="row" style={{margin:0,padding:0,marginTop:-10}}>
        {ads && ads.length >= 4 ? (
            <Slider {...settings1} slidesToShow={windowWidth > 768 ? 4 : 1}>
            {ads && ads.length > 0 ? (
            ads.map((ad) => (
            <AdsContainer
                key={ad._id}
                id={ad._id}
                name={ad.name}
                description={ad.description}
                town={ad.town ? ad.town.name : ''}
                state={ad.state ? ad.state.name : ''}
                industry={ad.industry ? ad.industry.name : ''}
                category={ad.category ? ad.category.name : ''}
                image={ad.image.url}

                price={ad.price}
              />
              ))
              ) : null}
            </Slider>
          ) : (
            ads.map((ad) => (
                <div className="col-lg-3 sm-12" style={{margin:0,padding:0}}>
                <div className="row" style={{margin:0,padding:0}} >
              <AdsContainer 
                key={ad._id}
                id={ad._id}
                name={ad.name}
                description={ad.description}
                town={ad.town ? ad.town.name : ''}
                state={ad.state ? ad.state.name : ''}
                industry={ad.industry ? ad.industry.name : ''}
                category={ad.category ? ad.category.name : ''}
                image={ad.image.url}
                price={ad.price}
              />
              </div>
              </div>
            ))
          )}
        </div>

    </div>

  )
}

export default AdsSameState