import { createReducer } from "@reduxjs/toolkit";
import {  CLEAR_ERRORS, USER_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_RESET, USER_DETAILS_SUCCESS } from "../constants/userConstants";


const initialState = {
  loading: true,
};

export const loginReducer = createReducer(
  {},
  {
    LOGIN_REQUEST: (state) => {
      state.loading = true;
      state.isAuthenticated = false;
    },
    LOGIN_SUCCESS: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.message = action.payload;
    },
    LOGIN_FAILURE: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },

    LOAD_USER_REQUEST: (state) => {
      state.loading = true;
    },
    LOAD_USER_SUCCESS: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    LOAD_USER_FAILURE: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
    },

    LOGOUT_REQUEST: (state) => {
      state.loading = true;
    },
    LOGOUT_SUCCESS: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.message = action.payload;
    },
    LOGOUT_FAILURE: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = action.payload;
    },

    CLEAR_ERRORS: (state) => {
      state.error = null;
    },
    CLEAR_MESSAGE: (state) => {
      state.message = null;
    },
  }
);

export const userDetailsReducer = (state = { user: {} }, { type, payload }) => {
  switch (type) {
      case USER_DETAILS_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case USER_DETAILS_SUCCESS:
          return {
              loading: false,
              user: payload,
          };
      case USER_DETAILS_FAIL:
          return {
              ...state,
              loading: false,
              error: payload,
          };
      case USER_DETAILS_RESET:
          return {
              ...state,
              user: {},
          };
      case CLEAR_ERRORS:
          return {
              ...state,
              error: null,
          };
      default:
          return state;
  }
};
