import axios from "axios";

export const createNewSupport = ( state, town, mobile, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: "newSupportRequest",
    });

    const { data } = await axios.post(
      `/api/v1/user/createsupport`,
      {
        state, town, mobile, email, password
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "newSupportSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "newSupportFailure",
      payload: error.response.data.message,
    });
  }
};