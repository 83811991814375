import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../Actions/User';

import ProSetting1 from '../component/Pro/ProSetting1';
import ProSetting2 from '../component/Pro/ProSetting2';
import ProSetting3 from '../component/Pro/ProSetting3';
import ProSetting4 from '../component/Pro/ProSetting4';
import UpdateMobile from '../component/UpdateMobile';
import ChangePassword from '../component/ChangePassword';

const ProSetting = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const [activeDivision, setActiveDivision] = useState(null);

  const [menuState, setMenuState] = useState({
    prosetting1:true,
    prosetting2:false,
    prosetting3:false,
    prosetting4:false,
    UpdateMobile : false,
    ChangePassword: false,
  });

  const toggleSubMenu = (menuItem) => {
    // Create a new object with all sections hidden except the clicked one
    const newMenuState = {};
    Object.keys(menuState).forEach((key) => {
      newMenuState[key] = key === menuItem;
    });
    setMenuState(newMenuState);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const setSubMenu = (submenu) => {
    // Create a new object with all sections hidden except the specified submenu
    const newMenuState = {};
    Object.keys(menuState).forEach((key) => {
      newMenuState[key] = key === submenu;
    });
    setMenuState(newMenuState);
  };

  const profileCompletionPercentage =
  (user.town && user.state  ? 1.25 : 1)* 20 +
  (user.gender && user.dob  ? .25 : 0)*20 +
  (user.address && user.pincode  ? .25 : 0)*20 +
  (user.profilepic.url  ? .25 : 0)*20 +
  (user.prodata.industry && user.prodata.category1 ? .25 : 0)* 20 +
  (user.prodata.category2 && user.prodata.category3? .25 : 0)* 20 +
  (user.prodata.category4 && user.prodata.category5 ? .25 : 0)* 20 +
  (user.prodata.aadhaar && user.prodata.aadhaarimage ? .25: 0)* 20+
  (user.prodata.pan && user.prodata.panimage ? .25 : 0)* 20+
  (user.prodata.bankname && user.prodata.accountnumber ? .25 : 0)* 20+
  (user.prodata.holdername && user.prodata.ifsccode ? .25 : 0)* 20+
  (user.paymentpro == 'yes' ? 1.25 : 0)* 20;


  const isPersonalInfoIncomplete =
    !user.town &&
    !user.state &&
    !user.gender &&
    !user.dob &&
    !user.address &&
    !user.pincode &&
    !user.profilepic;

  const isProfessionalInfoIncomplete =
    !user.prodata.industry &&
    !user.prodata.category1 &&
    !user.prodata.category2 &&
    !user.prodata.category3 &&
    !user.prodata.category4 &&
    !user.prodata.category5;

  const isBankInfoIncomplete =
    !user.prodata.aadhaar &&
    !user.prodata.aadhaarimage &&
    !user.prodata.pan &&
    !user.prodata.panimage &&
    !user.prodata.bankname &&
    !user.prodata.accountnumber &&
    !user.prodata.holdername &&
    !user.prodata.ifsccode;

    const isRegistrationIncomplete = user.paymentpro !== 'yes';

  useEffect(() => {
    // Determine which division to display based on conditions
    if (isPersonalInfoIncomplete) {
      setActiveDivision('personalInfo');
    } else if (isProfessionalInfoIncomplete) {
      setActiveDivision('professionalInfo');
    } else if (isBankInfoIncomplete) {
      setActiveDivision('bankInfo');
    } else if (isRegistrationIncomplete) {
      setActiveDivision('registrationInfo');
    } else {
      // All sections are complete, no division to show
      setActiveDivision(null);
    }
  }, [isPersonalInfoIncomplete, isProfessionalInfoIncomplete, isBankInfoIncomplete, isRegistrationIncomplete]);


  return (
    <div style={{padding:20}}>
       {activeDivision === 'personalInfo' && (
        <div className="alert alert-danger" style={{ fontSize: 13 }}>
          Your profile is not ready. Please fill in the necessary personal and professional information and proceed to the payment gateway.
          You have to also pay a Lifetime registration fee of Rs 700 to complete your profile and start surfing jobs and accepting orders using OyeConnect.
          In addition, you can also update banking information to receive funds awarded after the completion of your orders.
        </div>
      )}

        {activeDivision === 'professionalInfo' && (
        <div className="alert alert-danger" style={{ fontSize: 13 }}>
        Congrats for completing your 1st Step.. Next please proceed to professional information, Banking Information and complete payment.
        Then you can surf job and accept order accordingly.
      </div>
      )}

        {activeDivision === 'bankInfo' && (
        <div className="alert alert-danger" style={{ fontSize: 13 }}>
        Congrats, your professional information is also complete. Now just complete Banking, Documentation and payment to complete profile and start surfing job and accept orders
      </div>
      )}

      {activeDivision === 'registrationInfo' && (
        <div className="alert alert-danger" style={{ fontSize: 13 }}>
        Your Banking Information is also complete. Last Step is to make the registration payment which is once in a lifetime. After registration payment
        you will be eligible for all benefits a pro has and accept order
      </div>
      )}
        <div className="progress" style={{ marginTop: '20px' }}>
          <div className="progress-bar" role="progressbar"
            style={{ width: `${profileCompletionPercentage}%` }}
            aria-valuenow={profileCompletionPercentage}
            aria-valuemin="0" aria-valuemax="100">
          {`${profileCompletionPercentage}% Complete`}
          </div>
        </div><br></br>

        <div className="row">
          <div className="col-lg-3" style={{padding:20,marginTop:-20,}}>
            <div className="row" style={{backgroundColor:'#EBECEC',borderRadius:20,height:460,padding:20}}>
              <div className="buttons">
                <p style={{padding:0,margin:0,color:'#004274',fontSize:'20px'}}><b>SETTINGS</b></p>
                <button style={{padding:0,margin:0,border:'none',textAlign:'left'}}  onClick={() => toggleSubMenu('prosetting1')}>Personal Information</button><br></br>
                <button style={{padding:0,margin:0,border:'none',textAlign:'left'}} onClick={() => toggleSubMenu('prosetting2')}>Professional Information</button><br></br>
                <button style={{padding:0,margin:0,border:'none',textAlign:'left'}} onClick={() => toggleSubMenu('prosetting3')}>Banking Information</button><br></br>
                <button style={{padding:0,margin:0,border:'none',textAlign:'left'}} onClick={() => toggleSubMenu('prosetting4')}>Payment</button><br></br>
                <button style={{padding:0,margin:0,border:'none',textAlign:'left'}} onClick={() => toggleSubMenu('UpdateMobile')}>Update Mobile Number</button><br></br>
                <button style={{padding:0,margin:0,border:'none',textAlign:'left'}} onClick={() => toggleSubMenu('ChangePassword')}>Change Password</button><br></br>
                <button style={{padding:0,margin:0,border:'none',textAlign:'left'}} onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </div>
          {menuState.prosetting1 && (
          <div className="col-lg-9" style={{height:460, overflowY:'scroll'}}>
            <ProSetting1 dispatch={dispatch} setSubMenu={setSubMenu}/>
          </div>
          )}
          {menuState.prosetting2 && (
          <div className="col-lg-9" style={{height:420, overflowY:'scroll'}}>
            <ProSetting2 dispatch={dispatch} setSubMenu={setSubMenu}/>
          </div>
          )}
          {menuState.prosetting3 && (
          <div className="col-lg-9" style={{height:420, overflowY:'scroll'}}>
            <ProSetting3 dispatch={dispatch} setSubMenu={setSubMenu}/>
          </div>
          )}
          {menuState.prosetting4 && (
          <div className="col-lg-9" style={{height:420, overflowY:'scroll'}}>
            <ProSetting4 dispatch={dispatch} setSubMenu={setSubMenu}/>
          </div>
          )}
          {menuState.UpdateMobile && (
          <div className="col-lg-9" style={{height:420, overflowY:'scroll'}}>
            <UpdateMobile />
          </div>
          )}
          {menuState.ChangePassword && (
          <div className="col-lg-9" style={{height:420, overflowY:'scroll'}}>
            <ChangePassword/>
          </div>
          )}
    </div>
  </div>
  )
}

export default ProSetting