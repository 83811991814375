import React, { useState } from 'react';
import axios from 'axios';

const CreateDisclaimer = () => {
  const [formData, setFormData] = useState({
    heading: '',
    description: '',
    subheadings: [
      { name: '', description: '' }, // Initial subheading
    ],
  });

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const subheadingsCopy = [...formData.subheadings];
    subheadingsCopy[index][name] = value;
    setFormData({
      ...formData,
      subheadings: subheadingsCopy,
    });
  };

  const addSubheading = () => {
    setFormData({
      ...formData,
      subheadings: [...formData.subheadings, { name: '', description: '' }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/v1/webcategory/createdisclaimer/', formData);
      console.log(response.data);
      // Handle success, clear form, show success message, etc.
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };

  return (
      <form onSubmit={handleSubmit}>
        <h4>Create Disclaimer</h4>
        <div>
          <label htmlFor="heading">Heading:</label><br/>
          <input
            type="text"
            id="heading"
            name="heading"
            value={formData.heading}
            onChange={(e) => setFormData({ ...formData, heading: e.target.value })}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label><br/>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </div>
        {formData.subheadings.map((subheading, index) => (
          <div key={index}>
            <label htmlFor={`subheading-${index}-name`}>Subheading Name:</label>
            <input
              type="text"
              id={`subheading-${index}-name`}
              name="name"
              value={subheading.name}
              onChange={(e) => handleInputChange(e, index)}
            />
            <label htmlFor={`subheading-${index}-description`}>Subheading Description:</label>
            <textarea
              id={`subheading-${index}-description`}
              name="description"
              value={subheading.description}
              onChange={(e) => handleInputChange(e, index)}
            />
          </div>
        ))}
        <button type="button" onClick={addSubheading}>
          Add Subheading
        </button>
        <button type="submit">Submit</button>
      </form>
  );
};

export default CreateDisclaimer;