import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style.css'

const ViewFlexslider = () => {
  const [flexsliders, setFlexsliders] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchFlexsliders = async () => {
      try {
        const response = await axios.get('/api/v1/flexslider/');
        setFlexsliders(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFlexsliders();
  }, []);

  // Use useEffect to handle animation and update the currentIndex
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % flexsliders.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [flexsliders]);


  return (
    <div className="flexslider-container">
    <div className="flexslider-wrapper" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
      {flexsliders.map((flexslider, index) => (
        <div
          key={flexslider._id}
          className={`flexslider-item ${index === currentIndex ? 'active' : ''}`}
        >
          <img src={flexslider.image.url} alt={flexslider.name} />
        </div>
      ))}
    </div>
  </div>
  );
};

export default ViewFlexslider;