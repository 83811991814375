import React, { useEffect } from 'react';

const RazorpayScriptLoader = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the script source to the Razorpay script URL
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';

    // Set async to true to load the script asynchronously
    script.async = true;

    // Add an onload event handler to execute code once the script is loaded
    script.onload = () => {
      console.log('Razorpay script loaded successfully');
    };

    // Append the script to the head of the document
    document.head.appendChild(script);

    // Clean up by removing the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render anything in the UI
};

export default RazorpayScriptLoader;