import React, { useEffect }  from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PremiumSameTown from '../component/PremiumSameTown';
import AdsSameTown from '../component/AdsSameTown';
import JobSameTown from '../component/JobSameTown';
import UserSameTown from '../component/UserSameTown';
import ProSameTown from '../component/ProSameTown'

import SupportEarningCount from '../component-micro/SupportEarningCount';
import UserTownCount from '../component-micro/UserTownCount';
import ProTownCount from '../component-micro/ProTownCount';
import AdsTownCount from '../component-micro/AdsTownCount';
import JobTownCount from '../component-micro/JobTownCount';
import PremiumTownCount from '../component-micro/PremiumTownCount';


const SupportHome = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.town) {
      navigate('/setting');
    }
  }, [user.town, navigate]);

  return (
    <div className="row" style={{margin:0,padding:10}}>
      <div className="col-lg-12" style={{margin:0}}>
        <div className="row" style={{margin:0,padding:10}}>
          <div className="col-lg-3" style={{margin:0}}>
            <SupportEarningCount/>
          </div>
          <div className="col-lg-3" style={{margin:0}}>
            <UserTownCount/>
          </div>
          <div className="col-lg-3" style={{margin:0}}>
            <ProTownCount/>
          </div>
          <div className="col-lg-3" style={{margin:0}}>
            <PremiumTownCount/>
          </div>
          <div className="col-lg-3" style={{margin:0}}>
            <AdsTownCount/>
          </div>
          <div className="col-lg-3" style={{margin:0}}>
            <JobTownCount/>
          </div>
        </div>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <PremiumSameTown/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <AdsSameTown/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <JobSameTown/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <ProSameTown/>
      </div>
      <div className="col-lg-12" style={{margin:0}}>
        <UserSameTown/>
      </div>
    </div>
  )
}

export default SupportHome