import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {  Link} from "react-router-dom";

const Adminpanel1 = () => {
  return (
    <div style={{marginLeft:10,marginTop:-5,padding:10,backgroundColor:'#D9E2DB', borderRadius:5, overflow:'hidden'}}>
      <Link to="/adminhome" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Home</Link><br></br>
      <Link to="/adminTopup" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;TopUp</Link><br></br>
      <Link to="/adminpremium" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Premium</Link><br></br>
      <Link to="/adminnews" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;News</Link><br></br>
      <Link to="/adminindustry" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Industry</Link><br></br>
      <Link to="/admincategory" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Category</Link><br></br>
      <Link to="/adminstate" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;State</Link><br></br>
      <Link to="/admintown" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Town</Link><br></br>
      <Link to="/adminusers" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Users</Link><br></br>
      <Link to="/adminpro" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Professionals</Link><br></br>
      <Link to="/adminsupport" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;All Support</Link><br></br>
      <Link to="/adminmessage" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Messages</Link><br></br>
      <Link to="/adminads" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;All Ads</Link><br></br>
      <Link to="/adminjob" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;All Job</Link><br></br>
      <Link to="/adminblog" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Blogs</Link><br></br>
      <Link to="/adminaffiliate" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Affiliate Data</Link><br></br>
      <Link to="/adminallorders" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;All Orders</Link><br></br>
      <Link to="/admincompletedorders" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Completed Orders</Link><br></br>
      <Link to="/adminpendingorders" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;incomplete Orders</Link><br></br>
      <Link to="/adminwithdrawalrequests" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Withdrawal requests</Link><br></br>
      <Link to="/adminpushnotification" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Create Push Notification</Link><br></br>
      <Link to="/adminaboutus" style={{color:'black',textDecoration:'none',fontSize:13}}>&nbsp;&nbsp;Create Aboutus</Link><br></br>
    </div>
  )
}

export default Adminpanel1