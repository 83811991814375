import React, { useEffect } from 'react';
import axios from 'axios';
import RazorpayScriptLoader from './RazorpayScriptLoader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProSetting4 = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    async function fetchOrderAndHandlePayment() {
      if (user.paymentpro === 'yes') {
        return; // Payment is complete, no need to proceed
      }

      // Create a Razorpay order on your backend
      const response = await axios.post('/api/v1/razorpay/create-order', {
        amount: 700, // Registration charge amount
        currency: 'INR', // Currency code (e.g., INR for Indian Rupees)
      });

      const { order_id } = response.data;

      // Initialize Razorpay
      const options = {
        key: 'rzp_test_3MHOhVTtg9Gbqt', // Your Razorpay Key ID
        amount: 700 * 100, // Amount in paise (700 INR * 100)
        currency: 'INR',
        name: 'Oyeconnecting Private Limited',
        description: 'Registration Charge',
        order_id: order_id,
        handler: function (response) {
          // Payment success callback
          const paymentData = {
            code: 'proreg',
            transactionid: response.razorpay_payment_id,
            user: user._id, // Get the user's ID from your Redux store
            amount: 700,
            status: 'paid',
          };

          // Save payment data in your database (you need to create this API endpoint)
          axios.post('/api/v1/razorpay/payment/success', paymentData);
          navigate('/');
          // Redirect or show a success message to the user
          // You can also dispatch actions to update your Redux store
        },
        prefill: {
          name: user.firstname,
          email: user.email,
          contact: user.mobile,
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    }

    fetchOrderAndHandlePayment();
  }, [navigate, user]);

  if (user.paymentpro === 'yes') {
    return (
      <div>
        <p>Payment is complete. Now you can start surfing jobs.</p>
      </div>
    );
  }

  return (
    <div>
      {/* Include the RazorpayScriptLoader component */}
      <RazorpayScriptLoader />
    </div>
  );
};

export default ProSetting4;