import React,{useEffect} from "react";
import {  BrowserRouter as Router,Routes, Route} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loadUser } from "./Actions/User";

import Header from './component/Header';
import HeaderAuth from './component/HeaderAuth';
import Footer from './component/Footer';
import FooterAuth from './component/FooterAuth.js';

import Home from './screen/Home';
import AboutUs from './screen/AboutUs';
import AffiliatePolicy from './screen/AffiliatePolicy';
import CodeOfConduct from './screen/CodeOfConduct';
import CopyrightPolicy from './screen/CopyrightPolicy';
import Disclaimer from './screen/Disclaimer';
import FeesAndCharges from './screen/FeesAndCharges';
import Investor from './screen/Investor';
import PrivacyPolicy from './screen/PrivacyPolicy';
import SupportDuties from './screen/SupportDuties';
import TermsAndCondition from './screen/TermsAndCondition';
import RegisterUser from './screen/RegisterUser';
import RegisterPro from './screen/RegisterPro';
import VerifyOTP from './screen/VerifyOTP';
import HelpAndSupport from './screen/HelpAndSupport';
import NotFound from './screen/NotFound';
import NotAuthorised from './screen/NotAuthorised';
import Blog from './screen/Blog';
import Search from './screen/Search';

import YourBlog from './screen/YourBlog';
import Message from './screen/Message';
import Notification from './screen/Notification';
import HomeAuth from './screen/HomeAuth';

import UserProfile from './screen-authenticated/UserProfile';
import ProProfile from './screen-authenticated/ProProfile';
import AdsProfile from './screen-authenticated/AdsProfile';
import JobProfile from './screen-authenticated/JobProfile';
import OrderProfile from './screen-authenticated/OrderProfile';
import BlogProfile from './screen-authenticated/BlogProfile';


import UserContact from './screen-user/UserContact';
import UserHome from './screen-user/UserHome';
import UserJob from './screen-user/UserJob';
import UserOrder from './screen-user/UserOrder';
import UserReferral from './screen-user/UserReferral';
import UserSetting from './screen-user/UserSetting';

import SupportAds from './screen-support/SupportAds';
import SupportJob from './screen-support/SupportJob';
import SupportHome from './screen-support/SupportHome';
import SupportOrder from './screen-support/SupportOrder';
import SupportReferral from './screen-support/SupportReferral';
import SupportSetting from './screen-support/SupportSetting';
import SupportUsers from './screen-support/SupportUsers';

import ProAds from './screen-pro/ProAds';
import ProContact from './screen-pro/ProContact';
import ProHome from './screen-pro/ProHome';
import ProOrder from './screen-pro/ProOrder';
import ProReferral from './screen-pro/ProReferral';
import ProSetting from './screen-pro/ProSetting';

import AdminAds from './screen-admin/AdminAds';
import AdminBlog from './screen-admin/AdminBlog';
import AdminCategory from './screen-admin/AdminCategory';
import AdminComment from './screen-admin/AdminComment';
import AdminHome from './screen-admin/AdminHome';
import AdminIndustry from './screen-admin/AdminIndustry';
import AdminJob from './screen-admin/AdminJob';
import AdminMessage from './screen-admin/AdminMessage';
import AdminNews from './screen-admin/AdminNews';
import AdminOrder from './screen-admin/AdminOrder';
import AdminPremium from './screen-admin/AdminPremium';
import AdminPro from './screen-admin/AdminPro';
import AdminReferral from './screen-admin/AdminReferral';
import AdminState from './screen-admin/AdminState';
import AdminSupport from './screen-admin/AdminSupport';
import AdminTown from './screen-admin/AdminTown';
import AdminUser from './screen-admin/AdminUser';
import AdminAboutUs from './screen-admin/AdminAboutUs';
import AdminAffiliatePolicy from './screen-admin/AdminAffiliatePolicy';
import AdminCodeOfConduct from './screen-admin/AdminCodeOfConduct';
import AdminCopyrightPolicy from './screen-admin/AdminCopyrightPolicy';
import AdminFeesAndCharges from './screen-admin/AdminFeesAndCharges';
import AdminSupportDuties from './screen-admin/AdminSupportDuties';
import AdminTermsAndCondition from './screen-admin/AdminTermsAndCondition';
import AdminDisclaimer from './screen-admin/AdminDisclaimer';
import AdminInvestor from './screen-admin/AdminInvestor';
import AdminPrivacyPolicy from './screen-admin/AdminPrivacyPolicy';

function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);


  const { isAuthenticated, user } = useSelector((state) => state.user);
  const role = user?.role;
  return (
    
    <Router>
      <div className={App} style={{margin:0,padding:0}}> {isAuthenticated ? <HeaderAuth/> :<Header/>} </div>
      <Routes>
      <Route exact path="/"  element={
            role === "user" ? (
              <UserHome />
            ) : role === "pro" ? (
              <ProHome />
            ) : role === "support" ? (
              <SupportHome />
            ) : role === "admin" ? (
              <AdminHome />
            ) : (
              <Home />
            )
          }
        />
        <Route exact path="/job"  element={
            role === "user" ? (
              <UserJob />
            ) : role === "pro" ? (
              <ProAds />
            ) : role === "support" ? (
              <SupportJob/>
            ) : role === "admin" ? (
              <AdminJob />
            ) : (
              <NotAuthorised />
            )
          }
        />
        <Route exact path="/order"  element={
            role === "user" ? (
              <UserOrder />
            ) : role === "pro" ? (
              <ProOrder />
            ) : role === "support" ? (
              <SupportOrder/>
            ) : role === "admin" ? (
              <AdminOrder />
            ) : (
              <NotAuthorised />
            )
          }
        />
         <Route exact path="/referral"  element={
            role === "user" ? (
              <UserReferral />
            ) : role === "pro" ? (
              <ProReferral />
            ) : role === "support" ? (
              <SupportReferral/>
            ) : role === "admin" ? (
              <AdminReferral />
            ) : (
              <NotAuthorised />
            )
          }
        />
         <Route exact path="/setting"  element={
            role === "user" ? (
              <UserSetting />
            ) : role === "pro" ? (
              <ProSetting />
            ) : role === "support" ? (
              <SupportReferral/>
            ) : role === "admin" ? (
              <AdminReferral />
            ) : (
              <NotAuthorised />
            )
          }
        />

      <Route exact path="/aboutus" element={<AboutUs/>} />
      <Route exact path="/affiliatepolicy" element={<AffiliatePolicy/>} />
      <Route exact path="/codeofconduct" element={<CodeOfConduct/>} />
      <Route exact path="/copyrightpolicy" element={<CopyrightPolicy/>} />
      <Route exact path="/disclaimer" element={<Disclaimer/>} />
      <Route exact path="/feesandcharges" element={<FeesAndCharges/>} />
      <Route exact path="/investor" element={<Investor/>} />
      <Route exact path="/privacypolicy" element={<PrivacyPolicy/>} />
      <Route exact path="/termsandcondition" element={<TermsAndCondition/>} />
      <Route exact path="/helpandsupport" element={<HelpAndSupport/>} />
      <Route exact path="/registerUser" element={<RegisterUser/>} /> 
      <Route exact path="/registerPro" element={<RegisterPro/>} /> 
      <Route exact path="/verifyotp" element={<VerifyOTP/>} /> 

      <Route exact path="/blog" element={<Blog/>} /> 
      <Route exact path="/search" element={<Search/>} /> 

      <Route exact path="/userprofile/:id" element={isAuthenticated ? <UserProfile/> : <NotAuthorised/>} /> 
      <Route exact path="/proprofile/:id" element={isAuthenticated ? <ProProfile/> : <NotAuthorised/>} /> 
      <Route exact path="/adsprofile" element={isAuthenticated ? <AdsProfile/> : <NotAuthorised/>} /> 
      <Route exact path="/jobprofile" element={isAuthenticated ? <JobProfile/> : <NotAuthorised/>} /> 
      <Route exact path="/blogprofile/:id" element={isAuthenticated ? <BlogProfile/> : <NotAuthorised/>} /> 
      <Route exact path="/orderprofile/:id" element={isAuthenticated ? <OrderProfile/> : <NotAuthorised/>} />
      
      <Route exact path="/myblog" element={isAuthenticated ? <YourBlog/> : <NotAuthorised/>} /> 
      <Route exact path="/notification" element={isAuthenticated ? <Notification/> : <NotAuthorised/>} />

      <Route exact path="/message" element={isAuthenticated ? <Message/> : <NotAuthorised/>} /> 
      <Route path="/direct/t/:chatId/:userId" element={isAuthenticated ? <Message/> : <NotAuthorised/>} /> 

      <Route exact path="/usercontact" element={ role === "user" ? <UserContact/> : <NotAuthorised/>} />  

      <Route exact path="/procontact" element={ role === "pro" ? <ProContact/> : <NotAuthorised/>} /> 

      <Route exact path="/supportads" element={ role === "support" ? <SupportAds/> : <NotAuthorised/>} /> 
      <Route exact path="/supportjob" element={ role === "support" ? <SupportJob/> : <NotAuthorised/>} /> 
      <Route exact path="/supportsetting" element={ role === "support" ? <SupportSetting/> : <NotAuthorised/>} /> 
      <Route exact path="/supportusers" element={ role === "support" ? <SupportUsers/> : <NotAuthorised/>} /> 
      <Route exact path="/supportduties" element={ role === "support" ? <SupportDuties/> : <NotAuthorised/>} />

      <Route exact path="/adminads" element={ role === "admin" ?  <AdminAds/> : <NotAuthorised/>} /> 
      <Route exact path="/adminblog" element={ role === "admin" ? <AdminBlog/> : <NotAuthorised/>} /> 
      <Route exact path="/admincategory" element={ role === "admin" ? <AdminCategory/> : <NotAuthorised/>} /> 
      <Route exact path="/admincomment" element={ role === "admin" ? <AdminComment/> : <NotAuthorised/>} />
      <Route exact path="/adminindustry" element={ role === "admin" ? <AdminIndustry/> :<NotAuthorised/>} /> 
      <Route exact path="/adminjob" element={ role === "admin" ?  <AdminJob/> : <NotAuthorised/>} /> 
      <Route exact path="/adminmessage" element={ role === "admin" ? <AdminMessage/> : <NotAuthorised/>} /> 
      <Route exact path="/adminnews" element={ role === "admin" ?  <AdminNews/> : <NotAuthorised/>} /> 
      <Route exact path="/adminpremium" element={ role === "admin" ? <NotAuthorised/> : <AdminPremium/> }/> 
      <Route exact path="/adminpro" element={ role === "admin" ? <AdminPro/> : <NotAuthorised/>} /> 
      <Route exact path="/adminstate" element={ role === "admin" ? <AdminState/> : <NotAuthorised/>} /> 
      <Route exact path="/adminsupport" element={ role === "admin" ? <AdminSupport/> : <NotAuthorised/>} /> 
      <Route exact path="/admintown" element={ role === "admin" ? <AdminTown/> : <NotAuthorised/>} /> 
      <Route exact path="/adminuser" element={ role === "admin" ? <AdminUser/> : <NotAuthorised/>} /> 
      <Route exact path="/adminaboutus" element={ role === "admin" ? <AdminAboutUs/> : <NotAuthorised/>} /> 
      <Route exact path="/adminprivacypolicy" element={ role === "admin" ? <NotAuthorised/> : <AdminPrivacyPolicy/>} /> 
      <Route exact path="/adminaffiliatepolicy" element={ role === "admin" ? <AdminAffiliatePolicy/> : <NotAuthorised/>} /> 
      <Route exact path="/admincodeofconduct" element={ role === "admin" ? <AdminCodeOfConduct/> : <NotAuthorised/>} /> 
      <Route exact path="/admincopyrightcolicy" element={ role === "admin" ? <AdminCopyrightPolicy/> : <NotAuthorised/>} /> 
      <Route exact path="/adminfeesandcharges" element={ role === "admin" ? <AdminFeesAndCharges/> : <NotAuthorised/>} /> 
      <Route exact path="/admindisclaimer" element={ role === "admin" ? <AdminDisclaimer/> : <NotAuthorised/>} /> 
      <Route exact path="/admininvestor" element={ role === "admin" ? <AdminInvestor/> : <NotAuthorised/>} /> 
      <Route exact path="/adminsupportduties" element={ role === "admin" ? <AdminSupportDuties/> : <NotAuthorised/>} /> 
      <Route exact path="/admintermsandcondition" element={ role === "admin" ? <AdminTermsAndCondition/> : <NotAuthorised/>} /> 

      
      
      </Routes>
      <div className={App} style={{margin:0,padding:0}}> {isAuthenticated ? <FooterAuth/> : <Footer/>} </div>
    </Router>
  );
}

export default App;
