import {configureStore} from "@reduxjs/toolkit";
import { loginReducer, userDetailsReducer,} from "./Reducers/User";
import { JobReducer, likeJobReducer, myJobReducer, userJobReducer,JobNewReducer } from "./Reducers/Job";
import {AdReducer, likeAdsReducer, myAdsReducer, userAdsReducer,AdsNewReducer } from "./Reducers/Ads";

import {ProNewReducer, } from "./Reducers/Pro";
import {PremiumCountryReducer } from "./Reducers/Premium";

import { allMessagesReducer, newMessageReducer } from './Reducers/MessageReducer';
import { allChatsReducer, newChatReducer } from './Reducers/chatsReducer';

const store=configureStore({
    reducer:{
        user: loginReducer,
        userDetails: userDetailsReducer,

        Job : JobReducer,
        likeJob: likeJobReducer,
        userJob : userJobReducer,
        myJob: myJobReducer,
        JobNew : JobNewReducer,
        
        Ad : AdReducer,
        likeAds: likeAdsReducer,
        userAds: userAdsReducer,
        myAds: myAdsReducer,
        AdsNew : AdsNewReducer,

        ProNew : ProNewReducer,
        PremiumCountry : PremiumCountryReducer,

        allMessages: allMessagesReducer,
        newMessage: newMessageReducer,

        allChats: allChatsReducer,
        newChat: newChatReducer,
    }
});

export default store