import React from 'react'
import CreateJob from '../component/CreateJob'
import MyJob from '../component/Myjob'

const UserJob = () => {
  return (
    <div className="row" style={{margin:0,padding:10}}>
      <div className="col-lg-3" style={{margin:0}}>
        <CreateJob/>
      </div>
      <div className="col-lg-9" style={{margin:0}}>
        <MyJob/>
      </div>
    </div>
  )
}

export default UserJob