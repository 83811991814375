import { createReducer } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  premium: [],
  error: null,
};

export const PremiumCountryReducer = createReducer(initialState, {
  getPremiumRequest: (state) => {
    state.loading = true;
  },
  getPremiumSuccess: (state, action) => {
    state.loading = false;
    state.premium = action.payload.premium; // Update pro state
    // Update adCount state
  },
  getPremiumFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});


