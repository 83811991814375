import React, { useState } from 'react';
import axios from 'axios';

const AdminPrivacyPolicy = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
    setPreviewImage(URL.createObjectURL(selectedImage));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('image', image);
    const response = await axios.post('http://localhost:4000https://pitch-innovative-cardboard.glitch.me/api/v1/test', (formData));
    console.log(response.data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" value={name} onChange={handleNameChange} />
      </label>
      <label>
        Description:
        <textarea value={description} onChange={handleDescriptionChange} />
      </label>
      <label>
        Image:
        <input type="file" onChange={handleImageChange} />
        {previewImage && <img src={previewImage} alt="Preview" />}
      </label>
      <button type="submit">Submit</button>
    </form>
  );
}
export default AdminPrivacyPolicy