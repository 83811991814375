import React,{useState} from 'react';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import TextField from '@mui/material/TextField';

const RegisterUser = () => {
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [mobile, setmobile] = useState('');
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const [isRevealPwd,] = useState(false);
  const [cpassword, setCpassword] = useState('');
  const [isRevealCpassword,] = useState(false);
  const [referredby, setreferredby] = useState('');

  const registerUser = (e) => {
    e.preventDefault();
    axios.post('/api/v1/user/registeruser',
    {firstname,lastname,mobile,email,password,referredby})
    .then(res => {window.location.href = "/verifyotp";})
    .catch(err => console.log(err))
  };

  return (
    <Row class="registerpror" style={{paddingLeft:200,paddingRight:200, paddingTop:50, paddingBottom:50, margin:0}}>
      <div className="bg-white border flex flex-col gap-2 p-4 pt-10" style={{borderRadius:20}}>
      <h2>Register as User </h2>
        <form className="flex flex-col justify-center items-center gap-3 m-3 md:m-8">
        <TextField
          required
          id="outlined-required"
          placeholder="firstname"
          value={firstname}
          onChange={e => setfirstname(e.target.value)}
          style={{width:'100%'}}
        />
        <TextField
          required
          id="outlined-required"
          placeholder="Lastname"
          value={lastname}
          onChange={e => setlastname(e.target.value)}
          style={{width:'100%', marginTop:30}}
        />
        <TextField
          required
          id="outlined-required"
          placeholder="mobile number"
          value={mobile}
          onChange={e => setmobile(e.target.value)}
          style={{width:'100%', marginTop:30}}
        />
        <TextField
          required
          id="outlined-required"
          placeholder="Email Address"
          value={email}
          onChange={e => setemail(e.target.value)}
          style={{width:'100%', marginTop:30}}
        />
        <TextField
          required
          id="outlined-required"
          label="Password"
          type={isRevealPwd ? "text" : "password"}
          value={password}
          onChange={e => setPassword(e.target.value)}
          style={{width:'100%', marginTop:30}}
        />
        <TextField
          required
          id="outlined-required"
          label="Confirm Password"
          type={isRevealCpassword ? "text" : "password"}
          value={cpassword}
          onChange={e => setCpassword(e.target.value)}
          style={{width:'100%', marginTop:30}}
        />
          <TextField
          required
          id="outlined-required"
          placeholder="Referred by (optional)"
          value={referredby}
          onChange={e => setreferredby(e.target.value)}
          style={{width:'100%', marginTop:30}}
        />
        <button type="submit" className="bg-primary-blue font-medium py-2 rounded text-white w-full" 
        style={{ marginTop:30,backgroundColor:'#004274',width:'100%'}} onClick={registerUser} >Sign up</button>
        </form>
      </div>
    </Row>

  )
}

export default RegisterUser