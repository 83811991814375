import React, { useState } from 'react';
import axios from 'axios';

const CreateAboutUs = () => {
  const [formData, setFormData] = useState({
    heading: '',
    description: '',
    subheadings: [
      { name: '', description: '' }, // Initial subheading
    ],
    image: null,
  });

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const subheadingsCopy = [...formData.subheadings];
    subheadingsCopy[index][name] = value;
    setFormData({
      ...formData,
      subheadings: subheadingsCopy,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Read the file as a data URL and set it in the formData
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({
          ...formData,
          image: event.target.result, // Set the image as a base64-encoded string
        });
      };
      reader.readAsDataURL(file);
    }
  };


  const addSubheading = () => {
    setFormData({
      ...formData,
      subheadings: [...formData.subheadings, { name: '', description: '' }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/v1/webcategory/createaboutus/', formData);
      console.log(response.data);
      // Handle success, clear form, show success message, etc.
    } catch (error) {
      console.error(error);
      // Handle error, show error message, etc.
    }
  };

  return (
      <form onSubmit={handleSubmit}>
        <h4>Create About Us</h4>
        <div>
          <label htmlFor="heading">Heading:</label><br/>
          
          <input
            type="text"
            id="heading"
            name="heading"
            value={formData.heading}
            onChange={(e) => setFormData({ ...formData, heading: e.target.value })}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label><br/>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </div>
        <div>
        <label htmlFor="image">Image:</label><br />
        <input
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>
        {formData.subheadings.map((subheading, index) => (
          <div key={index}>
            <label htmlFor={`subheading-${index}-name`}>Subheading Name:</label>
            <input
              type="text"
              id={`subheading-${index}-name`}
              name="name"
              value={subheading.name}
              onChange={(e) => handleInputChange(e, index)}
            />
            <label htmlFor={`subheading-${index}-description`}>Subheading Description:</label>
            <textarea
              id={`subheading-${index}-description`}
              name="description"
              value={subheading.description}
              onChange={(e) => handleInputChange(e, index)}
            />
          </div>
        ))}
        <button type="button" onClick={addSubheading}>
          Add Subheading
        </button>
        <button type="submit">Submit</button>
      </form>
  );
};

export default CreateAboutUs;