import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  jobs: [],
};

export const likeJobReducer = createReducer(initialState, {
  likeJobRequest: (state) => {
    state.loading = true;
  },
  likeJobSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  likeJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  addCommentJobRequest: (state) => {
    state.loading = true;
  },
  addCommentJobSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  addCommentJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  deleteCommentJobRequest: (state) => {
    state.loading = true;
  },
  deleteCommentJobSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  deleteCommentJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  newJobRequest: (state) => {
    state.loading = true;
  },
  newJobSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  newJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  updateJobRequest: (state) => {
    state.loading = true;
  },
  updateJobSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  updateJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  deleteJobRequest: (state) => {
    state.loading = true;
  },
  deleteJobSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  deleteJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  followUserRequest: (state) => {
    state.loading = true;
  },
  followUserSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  followUserFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
  clearMessage: (state) => {
    state.message = null;
  },
});

export const JobReducer = createReducer(initialState, {
  getJobWithStateRequest: (state) => {
    state.loading = true;
  },
  getJobWithStateSuccess: (state, action) => {
    state.loading = false;
    state.jobs = action.payload.jobs; // Update jobs state
    state.totalJobs = action.payload.totalJobs // Update jobCount state
  },
  getJobWithStateFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});

export const myJobReducer = createReducer(initialState, {
  myJobRequest: (state) => {
    state.loading = true;
  },
  myJobSuccess: (state, action) => {
    state.loading = false;
    state.myjobs = action.payload.myjobs; // Update jobs state
    state.activeJobCount = action.payload.activeJobCount;
    state.inactiveJobCount = action.payload.inactiveJobCount; // Update jobCount state
  },
  myJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});

export const userJobReducer = createReducer(initialState, {
  userJobRequest: (state) => {
    state.loading = true;
  },
  userJobSuccess: (state, action) => {
    state.loading = false;
    state.posts = action.payload;
  },
  userJobFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});

export const JobNewReducer = createReducer(initialState, {
  getJobNewRequest: (state) => {
    state.loading = true;
  },
  getJobNewSuccess: (state, action) => {
    state.loading = false;
    state.jobs = action.payload.jobs; // Update Job state
    // Update adCount state
  },
  getJobNewFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});