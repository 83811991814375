
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminMenu from '../componentsmall/AdminMenu'
import React,{useState,useEffect} from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';

function AdminCategory(){
  const { user } = useSelector((state) => state.user);
    const [category,setcategory] = useState([]);
    const [Catindustry,setindustry] = useState([]);
    
    const [name,setName] = useState('');
    const [industry,setCatindustry] = useState('');

    useEffect(() => {
    axios.get ("/api/v1/industry")
    .then((res) => setindustry(res.data));
    }, [])
        
    useEffect(() => {
    axios.get ("/api/v1/category")
    .then((res) => setcategory(res.data));
  }, [])

  const postData = (e) => {
    e.preventDefault();
    if (!name) {
      alert('Please fill the name');
      return;
    }
    if (!name || !industry) {
      alert('Please select industry');
      return;
    }

    axios.post('/api/v1/category',{name, industry})
    .then(() => {
      // Fetch updated industry and category data
      axios.get("/api/v1/industry").then((res) => setindustry(res.data));
      axios.get("/api/v1/category").then((res) => setcategory(res.data));
    })
    .catch(err => console.log(err))
  };

  const deletePost = (_id) => {
    axios.delete(`/api/v1/category/${_id}`)
    .then(() => {
      // Fetch updated industry and category data
      axios.get("/api/v1/industry").then((res) => setindustry(res.data));
      axios.get("/api/v1/category").then((res) => setcategory(res.data));
    })
    .catch(err => console.log(err))
      };

  return (
    <Row style={{margin:0,padding:0, marginTop:10}}>
       <Col className="col-lg-" style={{margin:0,padding:10}}>
          <AdminMenu/>
       </Col>
      <Col className="col-lg-10" style={{margin:0,padding:25}}>
        <Row style={{margin:0,padding:0}}>
            <Col className="col-lg-9" style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
                <tr style={{backgroundColor:'black',color:'white'}}>
                    <td style={{width:'40%'}}>&nbsp;&nbsp;Industry</td>
                    <td style={{width:'40%'}}>&nbsp;&nbsp;Category</td>
                    <td style={{width:'20%'}}>&nbsp;&nbsp;Delete</td>        
                </tr>

                {category.map(item => (
                <tr style={{padding:'5px'}}>
                    <td style={{padding:'5px',width:'40%'}}>{item.name}</td>
                    {item.industry.map(ind => (
                    <td style={{padding:'5px',width:'40%'}}>{ind.name}</td>
                    ))}
                    <td style={{padding:'5px',width:'20%'}}>
                    <button style={{border:0,backgroundColor:"red",color:"white"}}  
                    key={item._id} onClick={() => deletePost(item._id)}>Remove</button></td>
                </tr>
            ))}
            </Col>
            <Col className="col-lg-3" style={{margin:0,paddingLeft:25}}>
                <form>
                    <label>Create Category</label>
                    
                    <input type="text" placeholder="category" value={name} onChange={(e) => setName(e.target.value)}
                    style={{fontSize:11,width:'100%',padding:10,borderRadius:10,borderWidth:1,borderColor:'grey'}}/>
                    <p></p>
                    
                    <select onChange={(e) => setCatindustry(e.target.value)} 
                    style={{fontSize:11,width:'100%',padding:10,borderRadius:10,borderWidth:1,borderColor:'grey'}}>
                    <option value="" style={{}}>Choose Industry</option>
                    {Catindustry && Catindustry.map((item) => (
                    <option key={item._id} value={item._id} style={{width:'100%'}}>{item.name}</option>
                    ))}
                    </select>
                    <p></p>
                   <button onClick={postData} 
                   style={{fontSize:11,width:'100%',padding:10,borderRadius:10,backgroundColor:'#004274',color:'white' }}>POST</button>
                </form>
            </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AdminCategory