import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row';

import axios from "axios";

function Statelist(){
    const [state,setStates] = useState([]);
    const [selectedState,setSelectedState] = useState([]);

useEffect(() => {
    axios.get ("api/v1/state")
    .then((res) => setStates(res.data));
  }, []);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  }

    return (
    <Row className="row" style={{margin:0,padding:0}}>
      <div className="compview" style={{margin:0,padding:0}}>
        <select id="state" name="state" value={selectedState} onChange={handleStateChange}
        style={{fontSize:11,padding:10,borderRadius:10,borderWidth:1,borderColor:'grey',width:'100%'}}>
          <option value="" disabled selected>Select State</option>
          {state.map(item => (
          <option value={item.id}>{item.name}</option>
          ))}
        </select>
      </div>
      <div className="mobileview" style={{margin:0,padding:0,}}>
        <select id="state" name="state" value={selectedState} onChange={handleStateChange}
        style={{fontSize:9,padding:6,borderRadius:6,borderWidth:1,borderColor:'grey', marginLeft:-8,width:'100%'}}>
          <option value="" disabled selected>Select State</option>
          {state.map(item => (
          <option value={item.id}>{item.name}</option>
          ))}
        </select>
      </div>
    </Row>

    )
}

export default Statelist