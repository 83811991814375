import React,{useState} from 'react';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import TextField from '@mui/material/TextField';

const VerifyOTP = () => {
  const [OTP, setOTP] = useState('');

  const registerPro = (e) => {
    e.preventDefault();
    axios.post('/api/v1/user/verifyotp',{OTP})
    .then(res => {window.location.href = "/";})
    .catch(err => console.log(err))
  };

  return (
    <Row class="registerpror" style={{paddingLeft:200,paddingRight:200, paddingTop:50, paddingBottom:50, margin:0}}>
      <div className="bg-white border flex flex-col gap-2 p-4 pt-10" style={{borderRadius:20,textAlign:'center'}}>
      <h4>Congratulations, Your Account has been Created </h4>
      <h6>Just enter the otp receieved on your Registered Mobile number to verify your account</h6>
        <form className="flex flex-col justify-center items-center gap-3 m-3 md:m-8">
        <TextField
          required
          id="outlined-required"
          placeholder="Please Enter OTP"
          value={OTP}
          onChange={e => setOTP(e.target.value)}
          style={{width:'100%'}}
        />
        <button type="submit" className="bg-primary-blue font-medium py-2 rounded text-white w-full" 
        style={{ marginTop:30,backgroundColor:'#004274',width:'100%'}} onClick={registerPro} >Sign up</button>
        </form>
      </div>
    </Row>

  )
}

export default VerifyOTP