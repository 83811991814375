import React,{useState,useEffect} from 'react';
import {  Link} from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HoverImage from "react-hover-image";
import { FaHome, FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import './Header.css'

import logo from '../image/logo.png'
import logofull from '../image/logofull.png';
import megamenu from '../image/industry.png';
import helpandsupport from '../image/HELP.png';
import helphover from '../image/helphover.png'
import playstore from '../image/playstore.png';
import appstore from '../image/appstore.png';
import registeruser from '../image/registeruser.png';
import registeruserhover from '../image/registeruserhover.png';
import registerpro from '../image/registerpro.png';
import Registerprohover from '../image/registerprohover.png';
import menu from '../image/menu.png';
import menuhover from '../image/menuhover.png';

import Statelist from '../componentsmall/Statelist';
import Megamenu from '../componentsmall/Megamenu';
import SearchInput from '../componentsmall/SearchInput';


const Header = () => {
  const [isShown, setIsShown] = useState(false);
  var [date,setDate] = useState(new Date());
  const [isFocusedMegamenu, setIsFocusedMegamenu] = useState(false);
  const [isFocusedMenu, setIsFocusedMenu] = useState(false);

  const handleInputFocusMegamenu = () => {
    setIsFocusedMegamenu(true);
  }

  const handleInputBlurMegamenu = () => {
    setIsFocusedMegamenu(false);
  }

  const handleInputFocusMenu = () => {
    setIsFocusedMenu(true);
  }

  const handleInputBlurMenu = () => {
    setIsFocusedMenu(false);
  }



    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });
  return (
    <div>
      <Row className="compview" style={{padding:10,margin:0, backgroundColor:'#E4E9ED',}}>
        <Col className="col-3" style={{margin:0,padding:0}}>
        <Link to="/"><img alt="logo" src={logofull} style={{width:'95%'}}/></Link>
        </Col>
        <Col className="col-9" style={{marginTop:-5}}>
          <Row style={{padding:0,margin:0}}>
              <Col className='col-6' style={{padding:0,margin:0}}>
                <Row  style={{padding:0,margin:0}}>
                  <Col className="col-3" style={{padding:4,margin:0}}>
                  <Statelist/>
                  </Col>
                  <Col className="col-3"style={{padding:4,margin:0}}>
                    <button onClick={() => setIsShown(!isShown)}
                    style={{color:'black',textDecoration:'none',border:'none',backgroundColor:'none',padding:0,margin:0}}>
                      <img alt="megamenu" src={megamenu} style={{width:'100%'}}/>
                    </button>
                  </Col>
                  <Col className="col-3" style={{padding:4,margin:0}}>
                  <Link to="/registeruser"><HoverImage style={{width:'100%'}} src={registeruser} hoverSrc={registeruserhover}/></Link>
                  </Col>
                  <Col className="col-3"style={{padding:4,margin:0}}>
                  <Link to="/registerpro" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={registerpro} hoverSrc={Registerprohover}/></Link>
                  </Col>
                </Row>
              </Col>
              <Col className='col-6' style={{padding:0,margin:0}}>
                <Row style={{padding:0,margin:0}}>
                  <Col className="col-3" style={{padding:4,margin:0}}>
                  <a href="https://playstore.com/" style={{color:'grey'}}><img alt="playstore" src={playstore} style={{width:'100%',padding:0,margin:0}}/></a>
                  </Col>
                  <Col className="col-3" style={{padding:4,margin:0}}>
                  <a href="https://www.apple.com/app-store/" style={{color:'grey'}}><img alt="appstore" src={appstore} style={{width:'100%'}}/></a>
                  </Col>
                  <Col className="col-3" style={{padding:4,margin:0}}>
                  <Link to="/helpandsupport" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={helpandsupport} hoverSrc={helphover}/></Link>
                  </Col>
                  <Col className="col-3" style={{padding:4,margin:0,marginTop:-7}}>
                    <text style={{fontSize:9,color:'#003C69'}}> {date.toLocaleDateString()} &nbsp; {date.toLocaleTimeString()}</text><br/>
                    <FaFacebook size={13}onMouseOver={({target})=>target.style.color='#013968'} onMouseOut={({target})=>target.style.color='grey'} style={{marginTop:-5}}/>&nbsp;
                    <FaInstagram size={13}onMouseOver={({target})=>target.style.color='#013968'} onMouseOut={({target})=>target.style.color='grey'} style={{marginTop:-5}}/>&nbsp;
                    <FaTwitter size={13} onMouseOver={({target})=>target.style.color='#013968'} onMouseOut={({target})=>target.style.color='grey'} style={{marginTop:-5}}/>&nbsp;
                    <FaLinkedin size={13} onMouseOver={({target})=>target.style.color='#013968'} onMouseOut={({target})=>target.style.color='grey'} style={{marginTop:-5}}/>&nbsp;&nbsp;
                    <Link to="/" style={{color:'grey'}}><FaHome size={27} onMouseOver={({target})=>target.style.color='grey'} onMouseOut={({target})=>target.style.color='#004274'}  style={{marginTop:-5,color:'#004274'}}/></Link>
                  </Col>
                </Row>
              </Col>
          </Row>
          <Row style={{padding:0,margin:0,marginTop:10}}>
            <Col className='col-10' style={{fontSize:12}}>
              <Link to="/aboutus" style={{color:'black',textDecoration:'none'}}>ABOUT US</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/blog" style={{color:'black',textDecoration:'none'}}>BLOG</Link> &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/news" style={{color:'black',textDecoration:'none'}}>NEWS</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/howitworks" style={{color:'black',textDecoration:'none'}}>HOW IT WORKS</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/registeruser" style={{color:'black',textDecoration:'none'}}>HIRE A PROFESSIONAL</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/earnwithus" style={{color:'black',textDecoration:'none'}}>EARN WITH US</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/privacypolicy" style={{color:'black',textDecoration:'none'}}>PRIVACY POLICY</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/contactus" style={{color:'black',textDecoration:'none'}}>CONTACT US</Link>
            </Col>
            <Col className="col-2" style={{padding:4,margin:0,marginTop:-11, }}>
            <SearchInput/>
            </Col>
          </Row>
        </Col>

        {isShown && (
        <Col className='dropdown' style={{padding:0,marginLeft:-10,marginTop:'85px',position:'fixed',zIndex:'4',width:'100%',backgroundColor:'#D8E4EB'}}>
            <Megamenu/>
            <button onClick={() => setIsShown(false)} style={{position:'fixed',top:100,right:20,textDecoration:'none',border:'none',backgroundColor:'#D8E4EB',color:'#004274'}}>x</button>
        </Col>
        )}
      </Row>

      <Row className="mobileview" style={{padding:0,margin:0, border:'none',backgroundColor:'#E4E9ED'}}>
          <Col className="col-3" style={{padding:8,margin:0}}>
          <Link to="/"><img alt="logo" src={logo} style={{width:'100%'}}/></Link>
          </Col>
          <Col className="col-9" style={{padding:0,margin:0}}>
            <Row className="row" style={{padding:8,margin:0}}>
                <Col className="col-4" style={{padding:2,margin:0,marginTop:6}}>
                <Statelist/>
                </Col>
                <Col className="col-4" style={{padding:2,margin:0,marginTop:6}}>
                <button onMouseEnter={handleInputFocusMegamenu} onMouseLeave={handleInputBlurMegamenu}
                  style={{color:'black',textDecoration:'none',border:'none',backgroundColor:'none',padding:0,margin:0}}>
                  <img alt="megamenu" src={megamenu} style={{width:'100%'}}/>
                  </button>
                  
                </Col>
                <Col className="col-2" style={{margin:0,padding:0}}></Col>
                <Col className="col-2" style={{margin:0,padding:2,marginTop:-4}}>
                <button 
                onClick={() => setIsFocusedMenu(!isFocusedMenu)}
                  style={{color:'black',textDecoration:'none',border:'none',backgroundColor:'none',padding:0,margin:0}}>
                 <HoverImage style={{width:'100%'}} src={menu} hoverSrc={menuhover}/>
                 </button>
                </Col>
            </Row>
            <Row className="row" style={{padding:8,margin:0,marginTop:-12,marginLeft:-6}}>
              <Col className="col-4" style={{padding:2,margin:0}}>
              <Link to="/helpandsupport" style={{color:'grey'}}><HoverImage style={{width:'100%'}} src={helpandsupport} hoverSrc={helphover}/></Link>
              </Col>
              <Col className="col-8" style={{padding:2,margin:0}}>
              <SearchInput/>
              </Col>
            </Row>
          </Col>

          <Col className={isFocusedMegamenu ? 'megamenu clicked' : 'megamenu'} style={{padding:0,margin:0,position:'fixed',top:0,zIndex:'4',height:'80%'}}>
              <Megamenu/>
          </Col>
          <Col className={isFocusedMenu ? 'menu clicked' : 'menu'} style={{padding:0,margin:0,position:'fixed',zIndex:5,right:0,top:0,height:'80%',backgroundColor:'#EBECEC'}}>
            <div className="" style={{padding:20,borderRadius:5,height:500,fontSize:17}}>
            <Link to="/" onClick={() => setIsFocusedMenu(!isFocusedMenu)} style={{color:'black',zIndex:5,textDecoration:'none'}}>HOME</Link><br></br>
              <Link to="/aboutus" onClick={() => setIsFocusedMenu(!isFocusedMenu)} style={{color:'black',zIndex:5,textDecoration:'none'}}>ABOUT US</Link><br></br>
              <Link to="/blog"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>BLOG</Link> <br></br>
              <Link to="/news"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>NEWS</Link><br></br>
              <Link to="/howitworks"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>HOW IT WORKS</Link><br></br>
              <Link to="/registerpro"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>SEARCH JOBS</Link><br></br>
              <Link to="/registeruser" onClick={() => setIsFocusedMenu(!isFocusedMenu)}   style={{color:'black',zIndex:5,textDecoration:'none'}}>HIRE A PROFESSIONAL</Link><br></br>
              <Link to="/quotes"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>QUOTES</Link><br/>
              <Link to="/helpandsupport"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>HELP AND SUPPORT</Link><br/>
              <Link to="/earnwithus"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>EARN WITH US</Link><br></br>
              <Link to="/privacypolicy"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>PRIVACY POLICY</Link><br></br>
              <Link to="/contactus" onClick={() => setIsFocusedMenu(!isFocusedMenu)}   style={{color:'black',textDecoration:'none'}}>CONTACT US</Link><br></br>
              <Link to="/termsandcondition"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>TERMS AND CONDITION</Link><br/>
              <Link to="/copyrightpolicy" onClick={() => setIsFocusedMenu(!isFocusedMenu)}   style={{color:'black',zIndex:6,textDecoration:'none'}}>COPYRIGHT POLICY</Link><br/>
              <Link to="/codeofconduct"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>CODE OF CONDUCT</Link><br></br>
              <Link to="/feesandcharges"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>FEES AND CHARGES</Link><br/>
              <Link to="/investor"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>INVESTOR</Link><br/>
              <Link to="/sitemap"  onClick={() => setIsFocusedMenu(!isFocusedMenu)}  style={{color:'black',textDecoration:'none'}}>SITEMAP</Link><br/>
              <Link to="/disclaimer" onClick={() => setIsFocusedMenu(!isFocusedMenu)}   style={{color:'black',textDecoration:'none'}}>DISCLAIMER</Link><br/><br/>
            </div>
          </Col>
      </Row>
    </div>
      

  )
}

export default Header