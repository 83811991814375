import axios from "axios";
import {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  USER_DETAILS_FAIL, 
  USER_DETAILS_REQUEST, 
  USER_DETAILS_SUCCESS,
  
} from "../constants/userConstants";

export const login = (mobile, password) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    const { data } = await axios.post(
      "api/v1/user/login",
      {
        mobile,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "LOGIN_FAILURE",
      payload: error.response.data.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: "LOGOUT_REQUEST",
    });

    const { data } = await axios.get("api/v1/user/logout");

    dispatch({
      type: "LOGOUT_SUCCESS",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "LOGOUT_FAILURE",
      payload: error.response.data.message,
    });
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "LOAD_USER_REQUEST",
    });

    const { data } = await axios.get("api/v1/user/meuser");

    dispatch({
      type: "LOAD_USER_SUCCESS",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "LOAD_USER_FAILURE",
      payload: error.response.data.message,
    });
  }
};


export const updateUser = (firstname, lastname, profilepic, email, gender, dob, town, state, address, pincode) => async (dispatch) => {
  try {
    dispatch({
      type: "updateProfileRequest",
    });

    const { data } = await axios.put(
      "/api/v1/user/updateuser",
      { firstname, lastname, profilepic, email, gender, dob, town, state, address, pincode},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "updateProfileSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateProfileFailure",
      payload: error.response.data.message,
    });
  }
};

export const updatePro1 = (firstname, lastname, profilepic, email, gender, dob, town, state, address, pincode) => async (dispatch) => {
  try {
    dispatch({
      type: "updateProfileRequest",
    });

    const { data } = await axios.put(
      "/api/v1/user/updatepro1",
      {firstname, lastname, profilepic, email, gender, dob, town, state, address, pincode},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "updateProfileSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateProfileFailure",
      payload: error.response.data.message,
    });
  }
};

export const updatePro2 = (industry, category1, category2, category3, category4, category5, experience) => async (dispatch) => {
  try {
    dispatch({
      type: "updateProfileRequest",
    });

    const { data } = await axios.put(
      "/api/v1/user/updatepro2",
      {industry, category1, category2, category3, category4, category5, experience},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "updateProfileSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateProfileFailure",
      payload: error.response.data.message,
    });
  }
};

export const updatePro3 = (aadhaar,aadhaarimage,pan,panimage, bankname, accountnumber, holdername, ifsccode) => async (dispatch) => {
  try {
    dispatch({
      type: "updateProfileRequest",
    });

    const { data } = await axios.put(
      "/api/v1/user/updatepro3",
      {aadhaar,aadhaarimage,pan,panimage, bankname, accountnumber, holdername, ifsccode},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "updateProfileSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateProfileFailure",
      payload: error.response.data.message,
    });
  }
};

export const getUserDetailsById = (userId) => async (dispatch) => {
  try {

      dispatch({ type: USER_DETAILS_REQUEST });
      const { data } = await axios.get(`/api/v1/user/${userId}`);

      dispatch({
          type: USER_DETAILS_SUCCESS,
          payload: data.user,
      });

  } catch (error) {
      dispatch({
          type: USER_DETAILS_FAIL,
          payload: error.response.data.message,
      });
  }
};





export const getMyJob = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: "myJobRequest",
    });

    const { data } = await axios.get(`/api/v1/user/myjob/${userId}`);
    dispatch({
      type: "myJobSuccess",
      payload: {
        myjobs: data.myjobs,
        activeJobCount: data.activeJobCount,
        inactiveJobCount: data.inactiveJobCount,
      },
    });
  } catch (error) {
    dispatch({
      type: "myJobFailure",
      payload: error.response.data.message,
    });
  }
};

export const getMyAds = () => async (dispatch) => {
  try {
    dispatch({
      type: "myAdsRequest",
    });

    const { data } = await axios.get("/api/v1/user/myads");
    dispatch({
      type: "myAdsSuccess",
      payload: {
        ads: data.ads,
        activeAdsCount: data.activeAdsCount,
        inactiveAdsCount: data.inactiveAdsCount,// Include the AdsCount in the payload
      },
    });
  } catch (error) {
    dispatch({
      type: "myAdsFailure",
      payload: error.response.data.message,
    });
  }
};



export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};