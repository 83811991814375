
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminMenu from '../componentsmall/AdminMenu'
import React,{useState,useEffect} from 'react';
import axios from "axios";

function AdminIndustry(){
    const [industry,setindustry] = useState([]);
    const [name,setName] = useState('');
    const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
    axios.get ("/api/v1/industry")
    .then((res) => setindustry(res.data));
    setIsLoading(false);
  }, [])

  const postData = (e) => {
    e.preventDefault();
    axios.post('/api/v1/industry',{name})
    .then(res => {window.location.reload();})
    .catch(err => console.log(err))
  };

  const deletePost = (_id) => {
    axios.delete(`/api/v1/industry/${_id}`)
    .then(res => {window.location.reload();})
    .catch(err => console.log(err))
      };

  return (
    <>
    {isLoading ? (
      <img src="loading.gif" alt="Loading" />
    ) : (
    <Row style={{margin:0,padding:0, marginTop:10}}>
      <Col className="col-lg-" style={{margin:0,padding:10}}>
          <AdminMenu/>
       </Col>
       <Col className="col-lg-10" style={{margin:0,padding:25}}>
        <Row style={{margin:0,padding:0}}>
            <Col className="col-lg-9" style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
                <tr style={{backgroundColor:'black',color:'white'}}>
                    <td>Name</td>
                    <td>Delete</td>        
                </tr>
                {industry.map(item => (
                
                <tr style={{padding:'5px'}}>
                    <td style={{padding:'5px'}}>{item.name}</td>
                    <td style={{padding:'5px'}}>
                    <button style={{border:0,backgroundColor:"red",color:"white"}}  
                    key={item._id} onClick={() => deletePost(item._id)}>Remove</button></td>
                </tr>
            ))}
            </Col>
            <Col className="col-lg-3" style={{margin:0,paddingLeft:15}}>
                <form>
                    <label>Create Industry</label>
                    <input type="text" placeholder="industry" value={name} onChange={(e) => setName(e.target.value)}/>
                   <button onClick={postData}>POST</button>
                </form>
            </Col>
        </Row>
      </Col>
    </Row>
    )}
    </>
  );
};

export default AdminIndustry