import axios from "axios";

export const likeAds = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "likeAdsRequest",
    });

    const { data } = await axios.get(`/api/v1/ads/${id}`);
    dispatch({
      type: "likeAdsSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "likeAdsFailure",
      payload: error.response.data.message,
    });
  }
};

export const addCommentOnAds = (id, comment) => async (dispatch) => {
  try {
    dispatch({
      type: "addCommentAdsRequest",
    });

    const { data } = await axios.put(
      `/api/v1/ads/comment/${id}`,
      {
        comment,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "addCommentAdsSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "addCommentAdsFailure",
      payload: error.response.data.message,
    });
  }
};

export const deleteCommentOnAds = (id, commentId) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteCommentAdsRequest",
    });

    const { data } = await axios.delete(`/api/v1/ads/comment/${id}`, {
      data: { commentId },
    });
    dispatch({
      type: "deleteCommentAdsSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteCommentAdsFailure",
      payload: error.response.data.message,
    });
  }
};

export const createNewAds = ( name, description, price, state, town, category, industry, image) => async (dispatch) => {
  try {
    dispatch({
      type: "newAdsRequest",
    });

    const { data } = await axios.post(
      `/api/v1/ads`,
      {
        name, description, price, state, town, category, industry, image,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "newAdsSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "newAdsFailure",
      payload: error.response.data.message,
    });
  }
};

export const updateAds = (caption, id) => async (dispatch) => {
  try {
    dispatch({
      type: "updateAdsRequest",
    });

    const { data } = await axios.put(
      `/api/v1/ads/${id}`,
      {
        caption,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: "updateAdsSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateAdsFailure",
      payload: error.response.data.message,
    });
  }
};

export const deleteAds = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteAdsRequest",
    });

    const { data } = await axios.delete(`/api/v1/ads/${id}`);
    dispatch({
      type: "deleteAdsSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteAdsFailure",
      payload: error.response.data.message,
    });
  }
};

export const getAdsWithState = (stateId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAdWithStateRequest",
    });

    const { data } = await axios.get(`/api/v1/ads/state/${stateId}`);
    dispatch({
      type: "getAdWithStateSuccess",
      payload: {
        ads: data.ads,
        totalAds : data.totalAds,
      },
    });
  } catch (error) {
    dispatch({
      type: "getAdWithStateFailure",
      payload: error.response.data.message,
    });
  }
};

export const getAdsNew = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAdsNewRequest",
    });

    const { data } = await axios.get(`/api/v1/ads/getactiveadsnew`);
    dispatch({
      type: "getAdsNewSuccess",
      payload: {
        ads: data.ads,
      },
    });
  } catch (error) {
    dispatch({
      type: "getAdsNewFailure",
      payload: error.response.data.message,
    });
  }
};

