import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HelpAndSupportPage = () => {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [userResults, setUserResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch towns from the server
  useEffect(() => {
    axios.get('/api/v1/state')
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {
        console.error('Error fetching towns:', error);
      });
  }, []);

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setSelectedState(stateId);
    searchUsers(stateId);
  };

  const searchUsers = (stateId) => {
    setIsLoading(true);
  
    // Make an API request to fetch users based on the selected town
    axios.get(`/api/v1/user/getallsupportwithstate/${stateId}`)
      .then((response) => {
        setUserResults(response.data.users);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container">
      <h1>Help and Support</h1>
      <div className="row">
        <div className="col-md-6">
          <h2>FAQ</h2>
          {/* Include your FAQ component here */}
        </div>
        <div className="col-md-6">
          <h2>Select a Town</h2>
          <select onChange={handleStateChange} value={selectedState}>
            <option value="">Select a State</option>
            {states.map((state) => (
              <option key={state._id} value={state._id}>
                {state.name}
              </option>
            ))}
          </select>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <h2>User Results</h2>
              <ul>
                {userResults.map((user) => (
                  <li key={user._id}>
                    <p>Town: {user.town.name} <br/>
                    Name: {user.mobile}<br/>
                    Email: {user.email}</p>
                    {/* Add more user details as needed */}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupportPage;

