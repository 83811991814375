import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import axios from "axios";

function Megamenu(){
    const [industry,setIndustry] = useState([]);

    useEffect(() => {
        axios.get ("api/v1/industry")
        .then((res) => setIndustry(res.data));
      }, []);
    
    return (
        <div className="p">
            <Row className="compview" style={{overflowX: 'hidden',overflowY:'scroll',height:'400px'}}>
                {industry && industry.map(item => (
                <Col className="col-lg-3" style={{paddingLeft:'30px',paddingRight:'30px',paddingTop:'10px'}}>
                    <h6>{item.name}</h6>
                    {item.category.map(category => (
                        <h6 style={{fontSize:'10px',lineHeight:'.6'}}>{category.name}</h6>
                    ))}
                </Col>
                ))}
            </Row>
            <Row className="mobileview" style={{overflow: 'scroll',width:'100%',height:600}}>
                <div className="" style={{padding:10,borderRadius:5,backgroundColor:'#D9DADA'}}>
                {industry && industry.map(item => (
                <Col className="col-12" style={{margin:0,padding:10}}>
                    <h5>{item.name}</h5>
                    {item.category.map(category => (
                        <h6 style={{fontSize:'12px',lineHeight:'1'}}>{category.name}</h6>
                    ))}
                </Col>
                ))}
                </div>
            </Row>
        </div>
    )
}

export default Megamenu