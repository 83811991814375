import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UpdateAboutUs = ({ entryId }) => {
  const [webcategory, setWebcategory] = useState({});
  const [formData, setFormData] = useState({
    heading: '',
    description: '',
    image: '',
    subheadings: [],
  });

  useEffect(() => {
    // Fetch the current webcategory data using the entryId prop
    axios.get(`/api/v1/webcategory/getwithid/${entryId}`)
      .then((response) => {
        setWebcategory(response.data.data);

        // Extract subheadings from the response and set them in the formData
        const { heading, description, image, subheading } = response.data.data;
        setFormData({
          heading,
          description,
          image,
          subheadings: subheading || [], // Set subheadings as an empty array if null
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [entryId]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubheadingChange = (e, index) => {
    const updatedSubheadings = [...formData.subheadings];
    updatedSubheadings[index][e.target.name.split('.')[1]] = e.target.value;
    setFormData({
      ...formData,
      subheadings: updatedSubheadings,
    });
  };

  const handleAddSubheading = () => {
    // Add a new empty subheading to the formData
    setFormData({
      ...formData,
      subheadings: [...formData.subheadings, { name: '', description: '' }],
    });
  };

  const handleRemoveSubheading = (index) => {
    // Remove the subheading at the specified index
    const updatedSubheadings = [...formData.subheadings];
    updatedSubheadings.splice(index, 1);
    setFormData({
      ...formData,
      subheadings: updatedSubheadings,
    });
  };

  
const handleSubmit = (e) => {
  e.preventDefault();

  // Remove any subheadings with empty name and description fields
  const filteredSubheadings = formData.subheadings.filter((subheading) => (
    subheading.name.trim() !== '' || subheading.description.trim() !== ''
  ));

  // Create the updated data object
  const updatedData = {
    heading: formData.heading,
    description: formData.description,
    subheadings: filteredSubheadings, // Use the filtered subheadings array
  };

  // Send PUT request to update the webcategory
  axios.put(`/api/v1/webcategory/updateaboutus/${entryId}`, updatedData)
    .then((response) => {
      console.log('Webcategory updated:', response.data.data);
      // Handle success or navigate to a different page
      window.location.reload();
    })
    .catch((error) => {
      console.error('Error updating webcategory:', error);
    });
};

  return (
    <div>
      <h2>Update Webcategory</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Heading</label>
          <input
            type="text"
            name="heading"
            value={formData.heading}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Image URL</label>
          <input
            type="text"
            name="image"
            value={formData.image}
            onChange={handleInputChange}
          />
        </div>
        {formData.subheadings.map((subheading, index) => (
          <div key={index}>
            <label>Subheading {index + 1}</label>
            <input
              type="text"
              name={`subheadings[${index}].name`}
              value={subheading.name}
              onChange={(e) => handleSubheadingChange(e, index)} // Handle subheading changes
            />
            <textarea
              name={`subheadings[${index}].description`}
              value={subheading.description}
              onChange={(e) => handleSubheadingChange(e, index)} // Handle subheading changes
            />
            <button
              type="button"
              onClick={() => handleRemoveSubheading(index)}
            >
              Remove Subheading
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddSubheading}>
          Add Subheading
        </button>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default UpdateAboutUs;