import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  ads: [],
};

export const likeAdsReducer = createReducer(initialState, {
  likeAdsRequest: (state) => {
    state.loading = true;
  },
  likeAdsSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  likeAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  addCommentAdsRequest: (state) => {
    state.loading = true;
  },
  addCommentAdsSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  addCommentAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  deleteCommentAdsRequest: (state) => {
    state.loading = true;
  },
  deleteCommentAdsSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  deleteCommentAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  newAdsRequest: (state) => {
    state.loading = true;
  },
  newAdsSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  newAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  updateAdsRequest: (state) => {
    state.loading = true;
  },
  updateAdsSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  updateAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  deleteAdsRequest: (state) => {
    state.loading = true;
  },
  deleteAdsSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  deleteAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  followUserRequest: (state) => {
    state.loading = true;
  },
  followUserSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  followUserFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
  clearMessage: (state) => {
    state.message = null;
  },
});

export const AdReducer = createReducer(initialState, {
  getAdWithStateRequest: (state) => {
    state.loading = true;
  },
  getAdWithStateSuccess: (state, action) => {
    state.loading = false;
    state.ads = action.payload.ads; // Update ads state
    state.totalAds = action.payload.totalAds // Update adCount state
  },
  getAdWithStateFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});

export const myAdsReducer = createReducer(initialState, {
  myAdsRequest: (state) => {
    state.loading = true;
  },
  myAdsSuccess: (state, action) => {
    state.loading = false;
    state.ads = action.payload.ads; // Update ads state
    state.activeAdsCount = action.payload.activeAdsCount;
    state.inactiveAdsCount = action.payload.inactiveAdsCount; // Update adsCount state
  },
  myAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});

export const userAdsReducer = createReducer(initialState, {
  userAdsRequest: (state) => {
    state.loading = true;
  },
  userAdsSuccess: (state, action) => {
    state.loading = false;
    state.posts = action.payload;
  },
  userAdsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});

export const AdsNewReducer = createReducer(initialState, {
  getAdsNewRequest: (state) => {
    state.loading = true;
  },
  getAdsNewSuccess: (state, action) => {
    state.loading = false;
    state.ads = action.payload.ads; // Update Ads state
    // Update adCount state
  },
  getAdsNewFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});