import React,{useState} from 'react';

import search from '../image/search.png';

const SearchInput = () => {
const [isFocused, setIsFocused] = useState(false);

const handleInputFocus = () => {
  setIsFocused(true);
}

const handleInputBlur = () => {
  setIsFocused(false);
}

    return (
    <div className="p" style={{margin:0,padding:0}}>
        <div className="compview" style={{margin:0,padding:0,width:'93%'}}>
            <div className={isFocused ? 'samrat focused' : 'samrat'} >
            <input
                type="text"
                className="search-input"
                style={{fontSize:11,padding:10,borderRadius:10,borderWidth:1,borderColor:'grey',width:'100%'}}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Search Jobs, Ads, Blogs and Professionals with filter"
            />
            </div>
        </div>
         <div className="compview" style={{ width:30,margin:0,padding:0,zindex:3,marginTop:-33, marginLeft:120}}>
            <img alt="search" src={search} style={{width:'100%',padding:0,margin:0}}/>
        </div>

        <div className="mobileview" style={{margin:0,padding:0,}}>
            <div className="samrat" >
            <input
                type="text"
                className="search-input"
                style={{fontSize:9,padding:5,borderRadius:6,borderWidth:1,borderColor:'grey',width:'100%'}}
                placeholder="Search Jobs and Professionals"
            />
            </div>
        </div>
         <div className="mobileview" style={{position:'fixed', width:20,margin:0,padding:0,zindex:3,marginTop:-23, marginLeft:145}}>
            <img alt="search" src={search} style={{width:'100%',padding:0,margin:0}}/>
        </div>
    </div>
    
    )
}
export default SearchInput