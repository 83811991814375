import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadUser, updatePro2 } from '../../Actions/User';
import axios from 'axios'
import Select from 'react-select';

const ProSetting2 = ({ dispatch, setSubMenu }) => {
  const user = useSelector((state) => state.user.user);

  const [experience, setExperience] = useState(user.prodata.experience);

  const [industries, setIndustries] = useState([]);
const [industry, setIndustry] = useState(user.prodata.industry);

const [categories, setCategories] = useState([]);
const [category, setCategory] = useState("");
const [category1, setCategory1] = useState(user.prodata.category1);
const [category2, setCategory2] = useState(user.prodata.category2);
const [category3, setCategory3] = useState(user.prodata.category3);
const [category4, setCategory4] = useState(user.prodata.category4);
const [category5, setCategory5] = useState(user.prodata.category5);

const industryOptions = industries.map(industry => ({
  value: industry._id,
  label: industry.name
}));

const category1Options = categories.map(category => ({
  value: category._id,
  label: category.name
}));
const category2Options = categories.map(category => ({
  value: category._id,
  label: category.name
}));
const category3Options = categories.map(category => ({
  value: category._id,
  label: category.name
}));
const category4Options = categories.map(category => ({
  value: category._id,
  label: category.name
}));
const category5Options = categories.map(category => ({
  value: category._id,
  label: category.name
}));

useEffect(() => {
  // Fetch industries from the server
  axios.get('/api/v1/industry')
    .then(response => {
      setIndustries(response.data);
    })
    .catch(error => {
      console.error('Error fetching industries:', error);
    });
},[]);

useEffect(() => {
  // Fetch categories based on the selected industry
  if (industry) {
    axios.get(`/api/v1/category/industry/${industry}`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }
}, [industry])

  const handleSubmit= async (e) => {
    e.preventDefault();
    await dispatch(updatePro2(industry, category1, category2, category3, category4, category5, experience));
    dispatch(loadUser());
    setSubMenu('prosetting3');
  };

  useEffect(() => {
    console.log('user.town:', user.town);
  }, [user.town]);

  return (
    <form onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-12">
              <h2>Professional Information</h2>  
            </div>
        </div>
            <div className="form-group">  
              <label htmlFor="industry">Industry</label>
              <Select
                options={industryOptions}
                value={industryOptions.find(option => option.value === industry)}
                onChange={selectedOption => setIndustry(selectedOption.value)}
              />
             </div><br></br>

            <div className="form-group">
                <label htmlFor="category">category1</label>
                  <Select
                    options={category1Options}
                    value={category1Options.find(option => option.value === category1)}
                    onChange={selectedOption => setCategory1(selectedOption.value)}
                  />
            </div><br></br>

            <div className="form-group">
                <label htmlFor="category">category2</label>
                  <Select
                    options={category2Options}
                    value={category2Options.find(option => option.value === category2)}
                    onChange={selectedOption => setCategory2(selectedOption.value)}
                  />
            </div><br></br>

            <div className="form-group">
                <label htmlFor="category">category3</label>
                  <Select
                    options={category3Options}
                    value={category3Options.find(option => option.value === category3)}
                    onChange={selectedOption => setCategory3(selectedOption.value)}
                  />
            </div><br></br>

            <div className="form-group">
                <label htmlFor="category">category4</label>
                  <Select
                    options={category4Options}
                    value={category4Options.find(option => option.value === category4)}
                    onChange={selectedOption => setCategory4(selectedOption.value)}
                  />
            </div><br></br>

            <div className="form-group">
                <label htmlFor="category">category5</label>
                  <Select
                    options={category5Options}
                    value={category5Options.find(option => option.value === category5)}
                    onChange={selectedOption => setCategory5(selectedOption.value)}
                  />
            </div><br></br>

            <div className="form-group">
              <label htmlFor="gender">Experience of years</label>
              <select
                className="form-control"
                id="experience"
                name="experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
              >
                 <option value="1">1 years</option>
                 <option value="2">2 years</option>
                 <option value="3">3 years</option>
                 <option value="4">4 years</option>
                 <option value="5">5 years</option>
                 <option value="6">6 years</option>
                 <option value="7">7 years</option>
                 <option value="8">8 years</option>
                 <option value="9">9 years</option>
                 <option value="10">10+ years</option>
                 <option value="0">Fresher - 0 years</option>
              </select>
            </div><br></br>
           
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
        </form>
  );
};

export default ProSetting2;
