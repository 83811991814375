import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CreateAboutUs from "./CreateAboutUs"
import UpdateAboutUs from "./UpdateAboutUs"

const AdminAboutUs = () => {
  const [aboutUsEntries, setAboutUsEntries] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);

  useEffect(() => {
    const fetchAboutUsEntries = async () => {
      try {
        const response = await axios.get('/api/v1/webcategory/viewaboutus/');
        setAboutUsEntries(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAboutUsEntries();
  }, []);

  const handleUpdate = (entryId) => {
    setSelectedEntryId(entryId);
  };


  const handleDelete = async (entryId) => {
    try {
      await axios.delete(`/api/v1/webcategory/deleteaboutus/${entryId}`);
      setAboutUsEntries(aboutUsEntries.filter((entry) => entry._id !== entryId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="row">
      
      <div className="col-9"style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
        <h4>About Us Entries</h4>
            <tr style={{backgroundColor:'black',color:'white'}}>
              <th>Heading</th>
              <th>Description</th>
              <th>Action</th>
              <th>Action</th>
            </tr>
            {aboutUsEntries.map((entry) => (
              <tr key={entry._id} style={{padding:'5px'}}>
                <td style={{padding:'5px'}}>{entry.heading}</td>
                <td style={{padding:'5px'}}>{entry.description}</td>
                <td>
                  <button style={{padding:'5px',border:0,backgroundColor:"red",color:"white"}} onClick={() => handleUpdate(entry._id)}>Update</button>
                </td>
                <td>
                  <button style={{padding:'5px',border:0,backgroundColor:"red",color:"white"}} onClick={() => handleDelete(entry._id)}>Delete</button>
                </td>
              </tr>
            ))}
      </div>
      <div className="col-3" style={{margin:0,padding:15,fontSize:'14px',height:'450px', overflowX:'auto'}}>
      {selectedEntryId ? (
          <UpdateAboutUs entryId={selectedEntryId}  />
        ) : (
          <CreateAboutUs />
        )}
      </div>
    </div>
  );
};

export default AdminAboutUs;