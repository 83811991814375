import React from "react";

const truncateString = (str, maxChars) => {
  if (str.length > maxChars) {
    return str.slice(0, maxChars) + "...";
  }
  return str;
};

const MyJobContainer = ({ _id, name, description, statetown, industry, category, image, price, owner}) => {
  const maxNameLength = 18; // Maximum character limit for description
  const maxDescriptionLength = 43; // Maximum character limit for description
  const maxStatetownLength = 19; // Maximum character limit for industry
  const maxIndustryLength = 18; // Maximum character limit for industry
  const maxCategoryLength = 15; // Maximum character limit for category
  
  return (
    <div className="col-12" style={{margin:0,padding:0,}}>
    <div className="row" style={{margin:0,padding:5}}>
    <div className="col-12" style={{margin:0, padding:3,borderRadius:'20px',border: '1px solid #969696',backgroundColor:"#EFEEEE"}}>
      <div className="row" style={{margin:0,padding:0}}>
        <div className="col-5"style={{padding:0,borderRadius:'20px'}}>
          <img src={image} style={{width:'100%',borderRadius:'20px'}}></img>
        </div>
        <div className="col-7"style={{marginLeft:0,padding:5}}>
          <div className="row">
            <h6 className="job-title" style={{lineHeight:1, color:"#004274"}}>{truncateString(name , maxNameLength)}</h6>
            <p style={{fontSize:11,lineHeight:1.3}}>
            <b>Description : </b>{truncateString(description, maxDescriptionLength)}<br />
            </p>
            <p style={{fontSize:11,lineHeight:1.3, marginTop:-5}}>
            <b>Amount :</b> ₹ {price}<br></br>
            <b>Location : </b>{truncateString(statetown, maxStatetownLength)}<br></br>
            <b>category : </b>{truncateString(category, maxCategoryLength)}<br></br>
            <b>industry : </b>{truncateString(industry, maxIndustryLength)}<br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default MyJobContainer;