import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';

const NotAuthorised = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  useEffect(() => {
    // When isAuthenticated becomes false, navigate to a different route
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);
  return (
    <div style={{marginTop:10}}>NotAuthorised</div>
  )
}

export default NotAuthorised